import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";

import {
  useAddTdVoteMutation,
  useDeleteTdVoteMutation,
  useTdVotesByIdQuery,
  useUpdateTdVoteMutation,
} from "api/memberPortal/voting/tdVotes/tdVotes.api";
import { Award } from "components/TRRTS/TRRTSManager/TQVoting/Award";
import VotingOverlay from "components/TRRTS/TRRTSManager/VotingOverlay";
import { useAuth } from "hooks/useAuth";
import { getCurrentDate } from "utils/timeUtils";

export default function TDVoting({ techDecision }) {
  const { currentUser } = useAuth();

  const getVotesResult = useTdVotesByIdQuery(techDecision.td_id);

  const [triggerDeleteTdVote, deleteTdVoteResult] = useDeleteTdVoteMutation();
  const [triggerUpdateTdVote, updateTdVoteResult] = useUpdateTdVoteMutation();
  const [triggerAddTdVote, addTdVoteResult] = useAddTdVoteMutation();

  const { data: votes } = getVotesResult;
  const isLoading =
    getVotesResult.isFetching ||
    deleteTdVoteResult.isLoading ||
    updateTdVoteResult.isLoading ||
    addTdVoteResult.isLoading;

  const currentUserVote =
    (votes && votes.find((vote) => vote.voter.id === currentUser.id)) ||
    undefined;

  const handleVote = async (vote) => {
    if (isLoading) return;

    if (currentUserVote) {
      if (vote === currentUserVote.approved) {
        await triggerDeleteTdVote(currentUserVote.id);
      } else {
        await triggerUpdateTdVote({
          userId: currentUserVote.id,
          body: {
            approved: vote,
            vote_date: getCurrentDate(),
          },
        });
      }
      return;
    }

    await triggerAddTdVote({
      approved: vote,
      td_id: techDecision.td_id,
      voter: currentUser.id,
      vote_date: getCurrentDate(),
    });
  };

  const getVoteCount = (approved) => {
    if (!votes) return 0;
    return votes.filter((v) => v.approved === approved).length;
  };

  const approvedVoteCount = getVoteCount(true);
  const rejectedVoteCount = getVoteCount(false);

  return (
    <Col xs={1} className="d-flex justify-content-center w-auto">
      <>
        {/* negative vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  ?.filter((vote) => vote.approved === false)
                  .map((vote) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay voter={vote.voter} date={vote.vote_date} />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex">
            {currentUserVote?.approved === false ? (
              <ThumbDownIcon
                data-testid="thumb-down-icon"
                style={{
                  color: "var(--bs-danger)",
                }}
                className="clickable"
                onClick={() => handleVote(false)}
              />
            ) : (
              <ThumbDownOffAltIcon
                data-testid="thumb-down-off-icon"
                className="clickable"
                onClick={() => handleVote(false)}
              />
            )}
            <h4 className="mx-3">{rejectedVoteCount}</h4>
          </div>
        </OverlayTrigger>

        {/* positive vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  ?.filter((vote) => vote.approved === true)
                  .map((vote) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay voter={vote.voter} date={vote.vote_date} />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex">
            {currentUserVote?.approved ? (
              <ThumbUpIcon
                data-testid="thumb-up-icon"
                style={{
                  color: "var(--bs-success)",
                }}
                className="clickable"
                onClick={() => handleVote(true)}
              />
            ) : (
              <ThumbUpOffAltIcon
                data-testid="thumb-up-off-icon"
                className="clickable"
                onClick={() => handleVote(true)}
              />
            )}
            <h4 className="mx-3">{approvedVoteCount}</h4>
          </div>
        </OverlayTrigger>

        {/* Award Icon */}
        <Award votes={votes} />
      </>
    </Col>
  );
}
