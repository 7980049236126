import { Col, Form, Row, Button } from "react-bootstrap";

const NistFiles = ({
  pp67FileTypes,
  handleChange,
  nistEditedFileLabel,
  formInfo,
  nistFiles,
  removeFile,
}) => {
  return (
    <>
      <h6>NIST Review Files</h6>
      <Form.Text className="text-muted fst-italic">
        Required for Certificate Review
      </Form.Text>
      <Col xl={3} sm={12}>
        <Form.Group
          className="mb-4 text-start"
          controlId="nist_file_display_name"
        >
          <Form.Label className="small text-secondary">Type</Form.Label>
          <Form.Select name="nist_file_display_name" onChange={handleChange}>
            <option value=""></option>
            {pp67FileTypes.map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xl={3} sm={12}>
        <Form.Group
          className="mb-4 text-start"
          controlId="nist_file_public_status"
        >
          <Form.Label className="small text-secondary">
            Document Visibility
          </Form.Label>
          <Form.Select name="nist_file_public_status" onChange={handleChange}>
            <option value=""></option>
            <option value="Public">Public</option>
            <option value="Proprietary">Proprietary</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xl={6} sm={12}>
        <Form.Group controlId="nist_file_label">
          <Form.Label className="small text-secondary">File Label</Form.Label>
          <Form.Control
            value={nistEditedFileLabel}
            type="text"
            name="nist_file_label"
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col xl={6} sm={12} className="mb-3">
        <Form.Group controlId="nist_file">
          <Form.Label className="small text-secondary">Attach Files</Form.Label>
          <Form.Control
            type="file"
            name="nist_file"
            onChange={handleChange}
            disabled={
              !formInfo.nist_file_public_status ||
              !formInfo.nist_file_display_name
            }
          />
        </Form.Group>
      </Col>
      <Row className="mb-3">
        <Col lg={10}>
          {nistFiles?.length > 0 && (
            <div className="border">
              <p className="mx-3 mt-2 fw-bold">Attached Files</p>
              {nistFiles
                .filter((file) => file?.active)
                ?.map((file, idx) => (
                  <Row
                    className="m-3 d-flex justify-content-around"
                    key={file.file_id}
                  >
                    <Col sm={4}>{file.file_display_name}</Col>
                    <Col sm={4}>{file.file_label}</Col>
                    <Col sm={2}>{file.private ? "Proprietary" : "Public"}</Col>
                    <Col sm={1} className="d-flex justify-content-center">
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => removeFile(file, idx)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default NistFiles;
