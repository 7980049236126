import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

export default function ScheduleMeetingModal({
  show,
  handleClose,
  product,
  user,
  meetingInfo,
  refetch,
  progressPoint, // we don't want schedule meeting modal to update progress point 2 - commenting out for now
  refetchProgress,
  isEdit,
  validationTeam,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [meeting, setMeeting] = useState({});
  const [eventId, setEventId] = useState();
  const [statusId, setStatusId] = useState();
  const [isInPerson, setIsInPerson] = useState(
    meetingInfo?.additional_comments ? false : true,
  );

  // function refreshPage() {
  //   window.location.reload(false);
  // }

  useEffect(() => {
    if (show) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_all_statuses/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          let currStatus = response.data.filter((status) =>
            status.text.includes("2"),
          );
          setStatusId(currStatus[0].id);
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${currStatus[0]?.id}&productId=${product?.product_id}`,
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              setEventId(response.data.id);
            })
            .catch((error) =>
              console.log("Unable to get Calendar Event: ", error),
            );
        })
        .catch((error) =>
          console.log("Unable to get Calendar Statuses: ", error),
        );
    }
  }, [show]);

  const handleChange = (e) => {
    setMeeting({ ...meeting, [e.target.name]: e.target.value });
  };

  // const handleUpdateProgressPoint = () => {
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/${progressPoint?.prog_id}/`,
  //       {
  //         status: "Completed",
  //         creator: user.id,
  //         created_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))
  //       },
  //       {
  //         withCredentials: true,
  //         headers: {
  //           "X-CSRFToken": csrfToken,
  //           Authorization: `Token ${authToken}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if(response.data.status === 'Update Already Exists'){
  //         refreshPage();
  //       } else {
  //         refetch();
  //         refetchProgress();
  //         sendAlerts();
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }

  const handleCreateCheckinMeeting = async (payload) => {
    let data = {
      creator: user.id,
      entrydate: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
    };

    if (payload) {
      data = {
        ...data,
        ...payload,
      };
    } else {
      data = {
        ...data,
        pid: product.product_id,
        additional_comments: meeting.additional_comments,
      };
    }

    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}top/checkin-meeting/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        // handleUpdateProgressPoint()
        // handleSendAlertAndMail(false)
        setMeeting({});
        handleClose();
        refetch();
      })
      .catch((error) => console.log(error));
  };

  const handleUpdateCheckinMeeting = async (payload) => {
    let data = {};

    if (payload) {
      data = {
        ...data,
        ...payload,
        creator: meeting.creator.id,
        editor: user.id,
        moddate: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
      };
    } else {
      data = {
        ...data,
        pid: product.product_id,
        additional_comments: meeting.additional_comments,
        creator: meeting.creator.id,
        editor: user.id,
        moddate: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
      };
    }

    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}top/checkin-meeting/${meeting.checkin_meeting_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        // handleSendAlertAndMail(true)
        refetch();
        setMeeting({});
        handleClose();
      })
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      if (meeting?.meeting_date) {
        createCalendarEvent(
          new Date(`${meeting?.meeting_date}T08:00:00Z`),
          new Date(`${meeting?.meeting_date}T17:00:00Z`),
        );
      }
      if (isInPerson) {
        const payload = {
          ...meeting,
          pid: product.product_id,
        };
        isEdit
          ? handleUpdateCheckinMeeting(payload)
          : handleCreateCheckinMeeting(payload);
      } else {
        isEdit ? handleUpdateCheckinMeeting() : handleCreateCheckinMeeting();
      }
    }
  };

  useEffect(() => {
    if (show === false) {
      setMeeting({ ...meetingInfo });
    }
  }, [meetingInfo, show]);

  // const handleSendAlertAndMail = (edit) => {
  //   let subject = `Check-In Meeting has been scheduled for VID ${product?.v_id}`
  //   if(edit){
  //     subject = `Check-In Meeting has been updated for VID ${product?.v_id}`
  //   }

  //   handleAlertAndMailer(
  //     csrfToken,
  //     authToken,
  //     {
  //       alert_type_id: product?.product_id,
  //       alert_type: "Product",
  //       subject: subject,
  //       recipients: {to: ["Lab"], cc: ["NIAP Management"]},
  //       alert_text: subject,
  //       alert_source: "Meeting"
  //     }
  //   )
  // };

  const createCalendarEvent = async (startDate, endDate) => {
    const title = `VID${product?.v_id} Check-In Meeting`;
    const requestData = {
      title: title,
      startDate: startDate,
      endDate: endDate,
      statusId: statusId,
      allDay: true,
      productId: product?.product_id,
    };
    if (eventId) {
      //Checks if event already exists
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${eventId}/`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {})
        .catch((error) =>
          console.log("Unable to update Calendar event: ", error),
        );
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`,
          requestData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {})
        .catch((error) =>
          console.log("Unable to create Calendar event: ", error),
        );
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Check-In Meeting Information for VID: {product.v_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-4">
            <Col>
              <Form.Check
                id="person"
                name="meeting_format"
                type="radio"
                label="Check-In Meeting In-Person/Video(Formal Meeting)"
                onChange={(e) => setIsInPerson(true)}
                defaultChecked={!meeting?.additional_comments}
              />
            </Col>
            <Col>
              <Form.Check
                id="email"
                name="meeting_format"
                type="radio"
                label="Electronic Check-In(eCheck-In)"
                onChange={(e) => setIsInPerson(false)}
                defaultChecked={meeting?.additional_comments}
              />
            </Col>
          </Row>
          {isInPerson ? (
            <>
              <Row className="mt-4">
                <Col>
                  <Form.Label>Date*</Form.Label>
                  <Form.Control
                    required
                    name="meeting_date"
                    type="date"
                    onChange={handleChange}
                    defaultValue={meeting?.meeting_date}
                  />
                </Col>
              </Row>
              {/* <Row className="mt-4">
                <Col>
                  <Form.Label>Start Time*</Form.Label>
                  <Form.Control
                    required
                    name="meeting_start"
                    type="time"
                    onChange={handleChange}
                    defaultValue={meeting?.meeting_start}
                  />
                </Col>
                <Col>
                  <Form.Label>End Time*</Form.Label>
                  <Form.Control
                    required
                    name="meeting_end"
                    type="time"
                    onChange={handleChange}
                    defaultValue={meeting?.meeting_end}
                  />
                </Col>
              </Row> */}
              {/* <Row className="mt-4">
                <strong>Who Needs to Attend</strong>
                <Col>
                  <Form.Label>Required Participants*</Form.Label>
                  <Select
                    isMulti
                    isSearchable
                    required
                    placeholder="Select All Required Participants"
                    options={userList}
                    onChange={(e) => setRequiredUsers(e)}
                    defaultValue={defaultUsers.defaultRequired}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor:
                          showValid &&
                          !requiredUsers.length > 0 &&
                          !meeting?.required_participants?.length > 0
                            ? "red"
                            : "#cccccc",
                      }),
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Optional Participants*</Form.Label>
                  <Select
                    isMulti
                    isSearchable
                    required
                    placeholder="Select All Optional Participants"
                    options={userList}
                    onChange={(e) => setOptionalUsers(e)}
                    defaultValue={defaultUsers.defaultOptional}
                  />
                </Col>
              </Row>{" "} */}
            </>
          ) : (
            <Row className="mt-4">
              <Col>
                <Form.Label>Additional Information*</Form.Label>
                <Form.Control
                  required
                  name="additional_comments"
                  as="textarea"
                  rows={3}
                  onChange={handleChange}
                  defaultValue={meeting?.additional_comments}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {isInPerson ? "Schedule" : "Send"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
