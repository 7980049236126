import axios from "axios";
import { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

import NistFiles from "./NistFiles";

const NistSection = ({
  product,
  protectionProfiles,
  handleChange,
  formInfo,
  setFormInfo,
  nist,
  setNist,
  nistFiles,
  setNistFiles,
  nistEditedFileLabel,
  pp67FileTypes,
}) => {
  const { authToken, csrfToken } = useAuth();
  const isPsd =
    protectionProfiles.filter(
      (pp) => pp.pp.tech_type === "Peripheral Sharing Devices",
    ).length > 0;

  useEffect(() => {
    loadProductById();
  }, [product]);

  useEffect(() => {
    if (nist.reqd && nist.crid) {
      loadFileByCrid();
    }
  }, [nist]);

  const removeFile = async (fileInfo, idx) => {
    if (fileInfo.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${fileInfo.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          if (fileInfo?.file_type === "nist") {
            loadFileByCrid();
          }
        })
        .catch((error) => console.log("Unable to update file", error));
    } else {
      if (fileInfo?.file_type === "nist") {
        const newFiles = nistFiles.slice();
        newFiles.splice(idx, 1);
        setNistFiles(newFiles);
      }
    }
  };

  const loadFileByCrid = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=nist&file_type_id=${nist.crid}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        console.log("nist files", response.data);
        setNistFiles(response.data?.filter((file) => file.active === true));
      })
      .catch((error) => console.log("Error getting nist files", error));
  };

  const loadProductById = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/get_by_product/?product=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setNist(response.data);
        setFormInfo({ reqd: response.data.reqd });
      })
      .catch(() => setNist({}));
  };

  return (
    <div className="border p-3">
      <Form.Group controlId="reqd" className="mb-3">
        <Form.Label>
          NIST Certificate Review ({isPsd ? "Required" : "Optional"} for
          selected technology types)
        </Form.Label>
        <p className="fst-italic">
          Note: Certificate Review data will not be saved until Check-out
          Package is submitted
        </p>
        <Row>
          <Col>
            <Form.Check
              type="radio"
              inline
              name="reqd"
              label="Required, Please Select Files Below"
              checked={formInfo.reqd}
              onChange={() =>
                handleChange({ target: { name: "reqd", value: true } })
              }
            />
          </Col>
          <Col>
            <Form.Check
              type="radio"
              inline
              name="reqd"
              label="No Certificate Review Needed"
              checked={!formInfo.reqd}
              onChange={() =>
                handleChange({ target: { name: "reqd", value: false } })
              }
            />
          </Col>
        </Row>
      </Form.Group>
      <Row>
        {formInfo.reqd ? (
          <NistFiles
            pp67FileTypes={pp67FileTypes}
            handleChange={handleChange}
            nistEditedFileLabel={nistEditedFileLabel}
            formInfo={formInfo}
            nistFiles={nistFiles}
            removeFile={removeFile}
          />
        ) : (
          <Form.Group controlId="just">
            <Form.Label>Justification*</Form.Label>
            <Form.Control
              as="textarea"
              name="just"
              onChange={handleChange}
              defaultValue={nist.just}
            />
          </Form.Group>
        )}
      </Row>
    </div>
  );
};

export default NistSection;
