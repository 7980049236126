import axios from "axios";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../Products/Helper/functions";
import HTMLEditor from "../../UI/HTMLEditor";

export default function AnnouncementsModal({
  show,
  handleShow,
  announcement,
  refetch,
  setAlert,
}) {
  const [validated, setValidated] = useState(false);
  const [cookies] = useCookies();
  const [newAnnouncement, setNewAnnouncement] = useState({});
  const [editedAnnouncement, setEditedAnnouncement] = useState({});
  const [announcementValid, setAnnouncementValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);
  // const [files, setFiles] = useState([]);
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const currentUser = useSelector((state) => state.user.value);

  useEffect(() => {
    if (announcement?.id) {
      setNewAnnouncement({ ...announcement });
    } else {
      setNewAnnouncement({});
    }
    setEditedAnnouncement({});
    setValidated(false);
    setAnnouncementValid(true);
  }, [show]);

  const resetForm = () => {
    setValidated(false);
    setNewAnnouncement({});
    setEditedAnnouncement({});
    // setFiles([]);
  };

  const handleSendAlertAndMail = (title, announcement) => {
    let subject = title;
    let recipients = {
      to: [],
    };

    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: announcement?.id,
      alert_type: "Announcement",
      subject: subject,
      recipients: recipients,
      alert_text: subject,
    });
  };

  const handleChange = (e) => {
    if (!announcement) {
      if (e.target.name === "types") {
        let values = [];
        for (let i = 0; i < e.target.selectedOptions.length; i++) {
          values.push(e.target.selectedOptions[i].value);
        }
        setNewAnnouncement({
          ...newAnnouncement,
          [e.target.name]: values?.join(","),
        });
      } else {
        setNewAnnouncement({
          ...newAnnouncement,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      if (e.target.name === "types") {
        let values = [];
        for (let i = 0; i < e.target.selectedOptions.length; i++) {
          values.push(e.target.selectedOptions[i].value);
        }
        setEditedAnnouncement({
          ...editedAnnouncement,
          [e.target.name]: values.join(","),
        });
      } else {
        setEditedAnnouncement({
          ...editedAnnouncement,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/`,
          {
            posted: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            creator: currentUser.id,
            entrydate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            ...newAnnouncement,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          handleSendAlertAndMail(newAnnouncement.title, response.data);
          handleShow();
          setAlert({
            message: "New Announcement Created and Email Sent!",
            type: "success",
          });
          refetch();
        })
        .catch((error) => {
          setAlert({
            message: "Error Creating New Announcement.",
            type: "danger",
          });
          console.log(error);
        });
    }
    setValidated(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/${announcement?.id}/`,
        {
          ...editedAnnouncement,
          creator: newAnnouncement.creator?.id,
          editor: currentUser.id,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        handleShow();
        refetch();
        setAlert({
          message: "Successfully Updated Event!",
          type: "success",
        });
      })
      .catch((error) => {
        handleShow();
        console.log("error: ", error);
        setAlert({ message: "Error updating Event.", type: "danger" });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleShow}
      size="lg"
      enforceFocus={enforceFocusModal}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header className="border-0" closeButton>
          <h3 className="text-bright-navy">
            {!announcement
              ? "+ ADD NEWS/UPDATES"
              : `EDIT NEWS/UPDATE - ${announcement?.title}`}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Title:*</Form.Label>
                  <Form.Control
                    className="form-indent-readOnly"
                    name="title"
                    onChange={handleChange}
                    value={
                      editedAnnouncement?.title ?? newAnnouncement?.title ?? ""
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="posted">
                  <Form.Label>Publication Date:*</Form.Label>
                  <Form.Control
                    type="date"
                    name="posted"
                    onChange={handleChange}
                    value={
                      editedAnnouncement?.posted
                        ? moment
                            .utc(editedAnnouncement?.posted)
                            .format("YYYY-MM-DD")
                        : newAnnouncement?.posted
                          ? moment
                              .utc(newAnnouncement?.posted)
                              .format("YYYY-MM-DD")
                          : moment().format("YYYY-MM-DD")
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="expires">
                  <Form.Label>Expiration Date:*</Form.Label>
                  <Form.Control
                    type="date"
                    name="expires"
                    onChange={handleChange}
                    value={
                      editedAnnouncement?.expires
                        ? moment
                            .utc(editedAnnouncement?.expires)
                            .format("YYYY-MM-DD")
                        : newAnnouncement?.expires
                          ? moment
                              .utc(newAnnouncement?.expires)
                              .format("YYYY-MM-DD")
                          : moment.utc().add(1, "year").format("YYYY-MM-DD")
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="event-type">
                  <Form.Label>Event Type:*</Form.Label>
                  <Form.Select
                    name="types"
                    onChange={handleChange}
                    value={
                      editedAnnouncement?.types?.split(",") ??
                      newAnnouncement?.types?.split(",") ??
                      []
                    }
                    required
                    multiple={true}
                  >
                    <option value=""></option>
                    {["Announcements NIAP", "Evolution", "Partner"].map(
                      (type, i) => (
                        <option key={i} value={`${i + 1}`}>
                          {type}
                        </option>
                      ),
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="link">
                  <Form.Label>Event URL:</Form.Label>
                  <Form.Control
                    className="form-indent-readOnly"
                    name="link"
                    type="url"
                    onChange={handleChange}
                    value={
                      editedAnnouncement?.link ?? newAnnouncement?.link ?? ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Group className="mb-3" controlId="announcement">
                  <Form.Label>Announcement*</Form.Label>
                  <HTMLEditor
                    name="announcement"
                    handleChange={handleChange}
                    setIsValid={setAnnouncementValid}
                    isValid={announcementValid}
                    defaultValue={announcement?.announcement}
                    setEnforceFocusModal={setEnforceFocusModal}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Container>
            <Row className="d-flex justify-content-end">
              <Col md={12} lg={2} className="mb-xs-3">
                <Button
                  variant="outline-primary"
                  onClick={handleShow}
                  className="w-100"
                >
                  Cancel
                </Button>
              </Col>
              <Col md={12} lg={2} className="mb-xs-3">
                <Button variant="warning" onClick={resetForm} className="w-100">
                  Reset
                </Button>
              </Col>
              <Col md={12} lg={3} className="mb-xs-3">
                {!announcement ? (
                  <Button variant="success" type="submit" className="w-100">
                    Add & Send
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    onClick={handleSave}
                    className="w-100"
                  >
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
