import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";

import { DefaultErrorResponse } from "api/common/types/response.types";
import { useLoginMutation } from "api/public/auth/auth.api";
import { LoginPayload } from "api/public/auth/auth.types";

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loginInfo, setLoginInfo] = useState<LoginPayload>({
    username: "",
    password: "",
  });

  const [
    triggerLogin,
    { error: loginError, data: loginData, isLoading, isSuccess, isError },
  ] = useLoginMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
  };

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    // We need to prevent the form from submitting on its own
    // We want to handle the submission ourselves
    event.preventDefault();
    triggerLogin(loginInfo);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!isSuccess) return;

    if (loginData.type === "setup") {
      // The user does not have a MFA method and will need to select one
      // This should only happen the first time a user logs in
      navigate("/login/mfa-select");
    }

    if (loginData.type == "existing-user") {
      // The user has previously logged in and setup an MFA method
      // We need to redirect them to the MFA process
      const { mfaMethod, ephemeralToken } = loginData;
      navigate(
        `/login/mfa-confirm?ephemeral-token=${ephemeralToken}&mfa-method=${mfaMethod}`,
      );
    }
  }, [isSuccess, loginData, navigate]);

  let errorMessage = undefined;
  if (isError) {
    if ("data" in loginError) {
      const { data } = loginError;
      errorMessage =
        (data as DefaultErrorResponse).error ||
        "Something went wrong. Please try again.";
    }
  }

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          Welcome to the Login Page for the NIAP Community
        </Card.Title>
        <p>If you are currently a NIAP Community member, please login below.</p>
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-4 text-start" controlId="username">
            <Form.Label className="small text-secondary">Username*</Form.Label>
            <Form.Control
              type="text"
              name="username"
              onChange={handleChange}
              required={true}
            />
          </Form.Group>
          <Form.Group className="mb-4 text-start" controlId="password">
            <Form.Label className="small text-secondary">Password*</Form.Label>
            <Input
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              id="password"
              className="form-control"
              name="password"
              disableUnderline={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                    <span className="visually-hidden">Toggle Visibility</span>
                  </IconButton>
                </InputAdornment>
              }
            />
          </Form.Group>

          {errorMessage && <p className="mb-4 text-danger">{errorMessage}</p>}

          <Button
            variant="warning"
            disabled={isLoading}
            type={isLoading ? "button" : "submit"}
          >
            {isLoading ? (
              <>
                <Spinner animation="border" size="sm" /> Login
              </>
            ) : (
              "Login"
            )}
          </Button>
        </Form>
        <p className="m-4 small text-secondary">
          <Link to="/login/forgot-password">Forgotten Password?</Link>
        </p>
      </Card.Body>
    </Card>
  );
};

export default LoginForm;
