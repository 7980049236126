import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import ReactSelect from "../../ReactSelect";

export default function Filter({ filters, setFilters, labs }) {
  const [currentFilters, setCurrentFilters] = useState({
    ...filters,
    status: filters?.status?.split(","),
  });
  const [formKey, setFormKey] = useState(Date.now());

  const handleChange = (event) => {
    if (event.target.name === "status") {
      let filter = currentFilters[event.target.name];
      if (!filter) {
        filter = [event.target.value];
      } else {
        if (event.target.checked) {
          filter.push(event.target.value);
        } else {
          const idx = filter.indexOf(event.target.value);
          filter.splice(idx, 1);
        }
      }
      setCurrentFilters({ ...currentFilters, [event.target.name]: filter });
    } else if (event.target.value.length === 0) {
      const newFilters = currentFilters;
      delete newFilters[event.target.name];
      setCurrentFilters({ ...newFilters });
    } else {
      setCurrentFilters({
        ...currentFilters,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilters({
      ...currentFilters,
      status: currentFilters?.status?.join(","),
    });
  };

  const handleClear = () => {
    setFilters({});
    setCurrentFilters({});
    setFormKey(Date.now());
  };

  const validatorOrgs = [
    { label: "NIAP/CCEVS", value: "NIAP" },
    { label: "NIAP/JHUAPL", value: "Johns Hopkins" },
    { label: "NIAP/Aerospace", value: "Aerospace" },
    { label: "NIAP/MITRE", value: "MITRE" },
  ];

  const validatorRoles = [
    { label: "Senior Validator", value: "Senior Validator" },
    {
      label: "Senior Validator (Assurance Maintenance)",
      value: "Senior Validator (Assurance Maintenance)",
    },
    { label: "Lead Validator", value: "Lead Validator" },
    {
      label: "Lead Validator (Assurance Maintenance)",
      value: "Lead Validator (Assurance Maintenance)",
    },
    { label: "Validator", value: "Validator" },
    { label: "ECR Team", value: "ECR Team" },
    { label: "Lab Participant", value: "Lab Participant" },
    { label: "Staff Liaison", value: "Staff Liaison" },
    {
      label: "Senior Validator (Trainee)",
      value: "Senior Validator (Trainee)",
    },
    {
      label: "Senior Validator (Assurance Maintenance, Trainee)",
      value: "Senior Validator (Assurance Maintenance, Trainee)",
    },
    { label: "Lead Validator (Trainee)", value: "Lead Validator (Trainee)" },
    {
      label: "Lead Validator (Assurance Maintenance, Trainee)",
      value: "Lead Validator (Assurance Maintenance, Trainee)",
    },
    { label: "ECR Team (Trainee)", value: "ECR Team (Trainee)" },
    { label: "Lab Participant (Trainee)", value: "Lab Participant (Trainee)" },
    { label: "Staff Liaison (Trainee)", value: "Staff Liaison (Trainee)" },
  ];

  const contractVehicles = [
    { label: "Unknown", value: 1 },
    { label: "Other", value: 2 },
    { label: "SMC", value: 3 },
    { label: "NASA", value: 4 },
  ];

  return (
    <Form onSubmit={handleSubmit} id="filters" key={formKey}>
      <Form.Group className="d-inline-block" controlId="from-date">
        <Form.Label>Time Frame</Form.Label>
        <Form.Control type="date" name="from_date" onChange={handleChange} />
      </Form.Group>
      <p className="d-inline-block p-3">to</p>
      <Form.Group className="d-inline-block" controlId="to-date">
        <Form.Label>Time Frame</Form.Label>
        <Form.Control type="date" name="to_date" onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="date-type" className="mb-3">
        <Form.Label>For The</Form.Label>
        <Form.Select name="date_type" onChange={handleChange}>
          <option value="">Select a type for the time frame</option>
          <option value="created_on">Entry Date</option>
          <option value="edited_on">Modification Date</option>
          <option value="est_completion_date">Estimated Completion Date</option>
          {/* <option value="due">Projected Start Date</option> */}
          <option value="kickoff_date">Kickoff Date</option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="validator_last_name" className="mb-3">
        <Form.Label>Team Member’s Last Name:</Form.Label>
        <Form.Control
          type="text"
          name="validator_last_name"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="validator_role" className="mb-3">
          Team Member's Role
        </Form.Label>
        <ReactSelect
          id="validator_role"
          name="validator_role"
          options={validatorRoles}
          newObj={currentFilters}
          handleChange={handleChange}
          isValid={true}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="validator_vehicle" className="mb-3">
          Team Member's Contract Vehicle
        </Form.Label>
        <ReactSelect
          id="validator_vehicle"
          name="validator_vehicle"
          options={contractVehicles}
          newObj={currentFilters}
          handleChange={handleChange}
          isValid={true}
        />
      </Form.Group>
      <Form.Group controlId="status" className="mb-3">
        <Form.Label>Status</Form.Label>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Pre-Evaluation"
              label="Pre-Evaluation"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Pre-Evaluation",
              )}
              id="status1"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Finalizing"
              label="Finalizing"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Finalizing",
              )}
              id="status2"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Terminated"
              label="Terminated"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Terminated",
              )}
              id="status3"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Withdrawn"
              label="Withdrawn"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Withdrawn",
              )}
              id="status4"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="In Progress"
              label="In Progress"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "In Progress",
              )}
              id="status5"
            />
          </Col>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="Completed"
              label="Completed"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "Completed",
              )}
              id="status6"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Check
              type="checkbox"
              name="status"
              value="In Assurance Maintenance"
              label="In Assurance Maintenance"
              onChange={handleChange}
              defaultChecked={currentFilters.status?.some(
                (status) => status === "In Assurance Maintenance",
              )}
              id="status7"
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="validator_org" className="mb-3">
        <Form.Label>Participating Organizations:</Form.Label>
        <ReactSelect
          name="validator_org"
          handleChange={handleChange}
          defaultValue={validatorOrgs?.filter((org) =>
            currentFilters?.validator_org?.includes(org.value),
          )}
          options={validatorOrgs}
          newObj={currentFilters}
          isMulti={true}
          isValid={true}
        />
      </Form.Group>
      <Form.Group controlId="associated_lab" className="mb-3">
        <Form.Label>Labs:</Form.Label>
        <ReactSelect
          name="associated_lab"
          handleChange={handleChange}
          defaultValue={labs?.filter((lab) =>
            currentFilters?.associated_lab?.includes(lab.value),
          )}
          options={labs}
          newObj={currentFilters}
          isMulti={true}
          isValid={true}
        />
      </Form.Group>
      <div className="mb-3">
        <Button type="submit" variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
        &nbsp;
        <Button type="reset" variant="outline-dark" onClick={handleClear}>
          Clear
        </Button>
      </div>
    </Form>
  );
}
