import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import { useInterval } from "../../../hooks/useInterval";

import TDDetailsAccordions from "./TDDetailsAccordions";
import TDDetailsHeader from "./TDDetailsHeader";
import TechDecisionSideInfo from "./TechDecisionSideInfo";

const CommunityTechDecisionDetails = () => {
  const { id } = useParams();
  const [techDecision, setTechDecision] = useState({});
  const [techDecisionHistory, setTechDecisionHistory] = useState([]);

  const { authToken, currentUser, permissions } = useAuth();

  const fetchTechDecision = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/by_identifier/${id}/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        let modTd = response.data;
        modTd.related_to = modTd.related_to ? modTd.related_to?.split(",") : [];
        setTechDecision(response.data);
      })
      .catch((error) => console.log(error));
  }, [authToken, id]);

  const fetchTechDecisionHistory = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/history/history_by_td/?identifier=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setTechDecisionHistory(response.data);
      })
      .catch((error) => console.log(error));
  }, [authToken, id]);

  useEffect(() => {
    fetchTechDecision();
    fetchTechDecisionHistory();
  }, [fetchTechDecision, fetchTechDecisionHistory]);

  useInterval(() => {
    fetchTechDecision();
    fetchTechDecisionHistory();
  }, 600000); //1 min

  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={8} lg={9} className="p-0">
          <TDDetailsHeader
            techDecision={techDecision}
            refetch={() => {
              fetchTechDecision();
              fetchTechDecisionHistory();
            }}
            currentUser={currentUser}
            permissions={permissions}
          />
          <TDDetailsAccordions
            techDecision={techDecision}
            refetch={() => {
              fetchTechDecision();
              fetchTechDecisionHistory();
            }}
            techDecisionHistory={techDecisionHistory}
          />
        </Col>
        <Col sm={12} md={4} lg={3}>
          <TechDecisionSideInfo techDecision={techDecision} />
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityTechDecisionDetails;
