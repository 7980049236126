import parse from "html-react-parser";
import moment from "moment";
import { useState } from "react";
import { Accordion } from "react-bootstrap";

import NistDeleteNoteModal from "./NistDeleteNoteModal";

const Note = ({ note, openNoteModal, isCurrentUsersNote, expandAll }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteNote, setToDeleteNote] = useState({});
  const openDeleteModal = (note) => {
    setToDeleteNote(note);
    setShowDeleteModal(true);
  };

  return (
    <div className="border border-secondary p-4 m-2 ms-3">
      <div className="text-end">
        <button
          className="btn btn-link d-inline-block"
          style={{ minWidth: 10 }}
          onClick={() => openNoteModal(note.note_id)}
        >
          Reply
        </button>
        {isCurrentUsersNote(note) && (
          <>
            <button
              className="btn btn-link d-inline-block"
              style={{ minWidth: 10 }}
              onClick={() => openNoteModal(null, note)}
            >
              Edit
            </button>
            <button
              className="btn btn-link d-inline-block"
              style={{ minWidth: 10 }}
              onClick={() => openDeleteModal(note)}
            >
              Delete
            </button>
          </>
        )}
      </div>
      <p className="text-end">
        Note by: {note.submitted_by?.first_name} {note.submitted_by?.last_name}{" "}
        <br />
        {note.submitted_on &&
          moment.utc(note.submitted_on).format("YYYY.MM.DD [at] HHmm")}
        <br />
        Visible to Labs: {note.visible_to_labs ? "Yes" : "No"}
      </p>
      <p className="fw-bold">{note.title}</p>
      <div>{note.note && parse(note.note)}</div>
      {note.responses && (
        <Accordion defaultActiveKey={expandAll ? note.note_id : null}>
          <Accordion.Item eventKey={note.note_id}>
            <Accordion.Header>
              {note.responses.length} Responses
            </Accordion.Header>
            <Accordion.Body>
              {note.responses.map((child) => (
                <Note
                  key={child.note_id}
                  note={child}
                  openNoteModal={openNoteModal}
                  openDeleteModal={openDeleteModal}
                  isCurrentUsersNote={isCurrentUsersNote}
                  expandAll={expandAll}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <NistDeleteNoteModal
        showDeleteModal={showDeleteModal}
        toDeleteNote={toDeleteNote}
        setShowDeleteModal={setShowDeleteModal}
      />
    </div>
  );
};

export default Note;
