import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";

import "../ProgressPointModal.css";

import { handleAlertAndMailer } from "components/Products/Helper/functions";
import HTMLEditor from "components/UI/HTMLEditor";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

const PP7_FILE_TYPES = [
  "Administrative Guide (AGD)",
  "Assurance Activity Report (AAR)",
  "CC Certificate",
  "Entropy Assessment Report (EAR)",
  "Other Documents",
  "Security Target",
  "Sync Meeting Notes",
  "Test Reports",
  "Validation Report",
  "Check-out Packages (Final Pkg)",
  "Evaluation Technical Report",
  "Other Documents",
];

export default function ProgressPointEditModal7({
  show,
  handleClose,
  product,
  setProduct,
  progressPoint,
  allFiles,
  isEdit,
  refetch,
  setRefetchNotes,
  setOverlayActive,
  setOverlayText,
}) {
  const { authToken, csrfToken, currentUser, permissions } = useAuth();
  const [formInfo, setFormInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [checkoutFiles, setCheckoutFiles] = useState([]);
  const [newCheckoutFiles, setNewCheckoutFiles] = useState([]);
  const [rejected, setRejected] = useState(false);
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [completionDateIds, setCompletionDateIds] = useState([]);
  const [submissionDateIds, setSubmissionDateIds] = useState([]);
  const [eventId, setEventId] = useState();
  const [statusId, setStatusId] = useState();
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const loadFileByPPFileSource = useCallback(() => {
    setCheckoutFiles(allFiles);
  }, [allFiles]);

  useEffect(() => {
    if (progressPoint) {
      setFiles([]);
      setCheckoutFiles([]);
      loadFileByPPFileSource();
    }
  }, [progressPoint, show]);

  useEffect(() => {
    if (progressPoint?.est_completion_dates) {
      let dates = [];
      for (let date in progressPoint?.est_completion_dates) {
        dates.push(progressPoint?.est_completion_dates[date]?.id);
      }
      setCompletionDateIds(dates);
    }
    if (progressPoint?.submission_dates) {
      let dates = [];
      for (let date in progressPoint?.submission_dates) {
        dates.push(progressPoint?.submission_dates[date]?.id);
      }
      setSubmissionDateIds(dates);
    }
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      const newFilesArr = newCheckoutFiles ? newCheckoutFiles : [];
      const newCheckoutFilesArr = checkoutFiles ? checkoutFiles : [];
      const file = {
        file_type: "product",
        file_source_type: "Validator Check Out",
        file_display_name: formInfo.file_display_name,
        uploaded_on: getISOCurrentDate(),
        file_name: e.target.files[0].name,
        file_label: formInfo.file_label,
        active: true,
        file: e.target.files[0],
        private: formInfo.file_public_status === "Public" ? false : true,
      };
      newFilesArr.push(file);
      newCheckoutFilesArr.push(file);
      setNewCheckoutFiles([...newFilesArr]);
      setCheckoutFiles([...newCheckoutFilesArr]);
    } else {
      if (e.target.name === "file_display_name") {
        setEditedFileLabel(e.target.value);
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          file_label: e.target.value,
        });
      } else if (e.target.name === "file_label") {
        setEditedFileLabel(e.target.value);
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      } else {
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      }
    }
  };

  const handleCheckboxReset = () => {
    const checkboxes = document.getElementsByName("files");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormInfo({});
    setFiles([]);
    handleCheckboxReset();
  };

  const handleUpdateProgressPoint = async (data) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        console.log("updated progress point successfully");
      })
      .catch((error) => console.log(error));
  };

  const handleSubmission = (e, statusString) => {
    e.preventDefault();

    const approveData = new FormData();

    let resubmissionCounter = progressPoint.resubmission_counter;
    const updatedProduct = { ...product };

    approveData.append(
      "validator_checkout_date",
      formInfo?.current_estimated_date
        ? formInfo?.current_estimated_date
        : new Date(product.validator_checkout_date).toJSON(),
    );

    // Update product with FormData values
    for (const [key, value] of approveData.entries()) {
      updatedProduct[key] = value;
    }

    setProduct(updatedProduct);
    updateProduct(updatedProduct);
    if (statusString === "Awaiting Checkout") {
      handleSendCheckoutPackageInPfaEmail();
    }

    const data = {
      product: product.product_id,
      progress_point: progressPoint.progress_point,
      status: statusString,
      checkout_rejected_rationale:
        statusString === "Rejected"
          ? formInfo?.checkout_rejected_rationale
          : null,
      resubmission_counter: resubmissionCounter,
      est_completion_dates: completionDateIds,
      submission_dates: submissionDateIds,
    };

    const updatedDateObjects = Object.keys(formInfo).filter(
      (key) =>
        key.includes("updated_act_completion") ||
        key.includes("updated_est_completion"),
    );

    if (updatedDateObjects.length > 0) {
      data["updated_resubmission_dates"] = updatedDateObjects.map((key) => ({
        [key.split("_")[0]]: formInfo[key],
      }));
    }

    //for updating pp6
    handleUpdateProgressPoint(data);

    //for updating pp7
    handleUpdateProgressPoint({
      product: product.product_id,
      progress_point: 7,
      status: "In Progress",
    });

    if (formInfo.notes) {
      handleAddNote();
    }

    if (newCheckoutFiles.length > 0) {
      handlePostCheckoutFile();
    }

    setFiles([]);
    handleClose(product);
    refetch();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let data = {
      product: product.product_id,
      progress_point: progressPoint.progress_point,
    };

    const updatedDateObjects = Object.keys(formInfo).filter(
      (key) =>
        key.includes("updated_act_completion") ||
        key.includes("updated_est_completion"),
    );

    if (updatedDateObjects.length > 0) {
      data["updated_resubmission_dates"] = updatedDateObjects.map((key) => ({
        [key.split("_")[0]]: formInfo[key],
      }));
    }
    await handleUpdateProgressPoint(data); // we send updated_resubmission_dates to updateProgressPoint

    // then we update the product
    await updateProduct();
  };

  const removeFile = async (fileInfo, idx) => {
    if (fileInfo.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${fileInfo.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          loadFileByPPFileSource();
        })
        .catch((error) => console.log("Unable to update file", error));
    } else {
      const newFiles = files.slice();
      newFiles.splice(idx, 1);
      setFiles(newFiles);
    }
  };

  const handleAddCheckoutFile = (e, checkoutFile) => {
    if (checkoutFile.file_source_type?.includes("Validator Check Out")) {
      handleEditCheckoutFile(e, checkoutFile);
    } else if (e.target.checked) {
      const newFilesArr = newCheckoutFiles ? newCheckoutFiles : [];
      newFilesArr.push(checkoutFile);
      setNewCheckoutFiles(newFilesArr);
    } else if (!e.target.checked) {
      let fileIdx = newCheckoutFiles.findIndex(
        (f) => f.file_id === checkoutFile.file_id,
      );
      const newFiles = newCheckoutFiles.slice();
      newFiles.splice(fileIdx, 1);
      setNewCheckoutFiles(newFiles);
    }
  };

  const handleEditCheckoutFile = (e, checkoutFile) => {
    if (!checkoutFile?.file_id) {
      return;
    }
    let data = {
      file_source_type: "Validator Check Out",
    };

    if (!e.target.checked) {
      data["file_source_type"] = "Other";
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${checkoutFile.file_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => console.log("updated file source type"))
      .catch((error) => console.log(error));
  };

  const handlePostCheckoutFile = () => {
    newCheckoutFiles.forEach((file) => {
      if (file.active === true) {
        delete file["uploaded_by"];
        if (!file.file_id) {
          const submittedFile = new FormData();
          submittedFile.append("file_type", "product");
          submittedFile.append("file_type_id", product.product_id);
          submittedFile.append("file", file.file);
          submittedFile.append("file_display_name", file.file_display_name);
          submittedFile.append("file_name", file.file_name);
          submittedFile.append("file_label", file.file_label);
          submittedFile.append("file_location", "uploads");
          submittedFile.append("file_mime_type", file?.file?.type);
          submittedFile.append("uploaded_on", getISOCurrentDate());
          submittedFile.append("uploaded_by", currentUser.id);
          submittedFile.append("file_source_type", "Validator Check Out");
          submittedFile.append("active", JSON.stringify(file.active));
          submittedFile.append("private", JSON.stringify(file.private));
          axios
            .post(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
              submittedFile,
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                  "Content-Type": "multipart/form-data",
                },
              },
            )
            .then(() => console.log("added checkout files"))
            .catch((error) => console.log(error));
        } else {
          //if the file_source_type hasn't already been updated to checkout
          if (!file.file_source_type?.includes("Validator Check Out")) {
            axios
              .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
                {
                  file_source_type: `${file.file_source_type},Validator Check Out`,
                },
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                    "X-CSRFToken": csrfToken,
                  },
                },
              )
              .then(() => {})
              .catch(() => {
                console.log("Error");
              });
          }
        }
      }
    });
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const handleAddNote = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: formInfo?.notes,
          note_type: "product",
          note_type_id: product?.product_id,
          submitted_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          submitted_by: currentUser.id,
          progress_point: progressPoint.progress_point,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setRefetchNotes(response.data.note_id))
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const handleSendCheckoutPackageInPfaEmail = () => {
    let subject = "";
    let recipients = {};
    subject = `Check-out Package Uploaded to VID ${product?.v_id} in the Project File Area`;
    recipients = { to: ["Validators"], cc: [] };

    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: product?.product_id,
      alert_type: "Product",
      subject: subject,
      recipients: recipients,
      alert_text: subject,
    });
  };

  const updateProduct = async (data) => {
    const submittedData = new FormData();
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        submittedData.append(key, value);
      }
    } else {
      submittedData.append("is_resubmission", product.isResubmission || "");
      submittedData.append("ear_required", product.ear_required || "");
      submittedData.append(
        "ear_previously_submitted",
        product.ear_previously_submitted || "",
      );
      submittedData.append("sync_required", product.sync_required || "");
      submittedData.append("post_certificate", product.post_certificate || "");
      if (formInfo.current_estimated_date) {
        submittedData.append(
          "validator_checkout_date",
          new Date(formInfo?.current_estimated_date).toJSON(),
        );
        createCalendarEvent(
          new Date(`${formInfo?.current_estimated_date}T00:00`),
        );
      } else {
        submittedData.append(
          "validator_checkout_date",
          new Date(product.validator_checkout_date).toJSON(),
        );
      }

      if (files.length > 0) {
        for (let f in files) {
          if (!files[f].file_mime_type) {
            submittedData.append("attachments_file", files[f].file);
            submittedData.append(
              "attachments_source_type",
              files[f].file_source_type,
            );
            submittedData.append(
              "attachments_type",
              files[f].file_display_name,
            );
            submittedData.append("attachments_uploaded", files[f].uploaded_on);

            submittedData.append("private", files[f].private);
            submittedData.append("file_label", files[f].file_label);
          }
        }
      }
    }

    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => {
        if (formInfo.notes) {
          handleAddNote();
        }
        if (newCheckoutFiles.length > 0) {
          handlePostCheckoutFile();
        }
        setFiles([]);
        handleClose(response.data);
        refetch();
      })
      .catch((error) => console.log("Unable to update product: ", error));
  };

  const createCalendarEvent = (startDate) => {
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);
    const title = `VID${product?.v_id} VALIDATOR CHECKOUT - PROGRESS POINT 7 Estimated Completion`;
    const requestData = {
      title: title,
      startDate: startDate,
      endDate: endDate,
      statusId: statusId,
      allDay: true,
      productId: product?.product_id,
    };

    if (eventId) {
      //Checks if event already exists
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${eventId}/`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) =>
          console.log("Unable to update Calendar event: ", error),
        );
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`,
          requestData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) =>
          console.log("Unable to create Calendar event: ", error),
        );
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        let currStatus = response.data?.filter((status) =>
          status.text.includes(progressPoint.progress_point),
        );
        setStatusId(currStatus[0]?.id);
        axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${currStatus[0]?.id}&productId=${product?.product_id}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            setEventId(response.data.id);
          })
          .catch(() => console.log("Unable to get Calendar Event."));
      })
      .catch(() => console.log("Unable to get Calendar statuses."));
  }, [progressPoint.progressPoint]);

  const getSubmitButton = () => {
    let button = null;

    if (
      isEdit &&
      progressPoint.status !== "Rejected" &&
      progressPoint.status !== "Amended Package Needed"
    ) {
      return button;
    }

    if (progressPoint.status === "Rejected") {
      button = (
        <Col sm={12} md={3} lg={2}>
          <Button
            variant="primary"
            onClick={(e) => handleSubmission(e, "Resubmitted")}
            style={{ whiteSpace: "nowrap" }}
          >
            Resubmit
          </Button>
        </Col>
      );
    } else {
      button = (
        <Col sm={12} md={3} lg={3}>
          <Button
            variant="success"
            onClick={(e) => handleSubmission(e, "Awaiting Checkout")}
            style={{ whiteSpace: "nowrap" }}
          >
            {progressPoint.status === "Amended Package Needed"
              ? "Submit Changes"
              : "Submit for Validation"}
          </Button>
        </Col>
      );
    }

    return button;
  };

  const showCurrentEstCompletionDateInput = () => {
    if (progressPoint?.resubmission_counter === null) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose();
        }}
        enforceFocus={enforceFocusModal}
      >
        <Form onReset={handleReset} id="progress-point-form">
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
              <h3 className="text-bright-navy">
                VALIDATOR CHECKOUT - PROGRESS POINT 7
                {isEdit ? (
                  " EDIT"
                ) : ["Not Started", "Est. Completion Date Set"]?.includes(
                    progressPoint?.status,
                  ) ? (
                  " INITIATE"
                ) : progressPoint?.status === "In Progress" ? (
                  " COMPLETE"
                ) : progressPoint?.progress_point === 6 ? (
                  " Check-Out"
                ) : (
                  <></>
                )}{" "}
                for VID: {product.v_id}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Row className="mt-3">
                    <Col sm={5} mb={2}>
                      {showCurrentEstCompletionDateInput() && (
                        <Form.Group controlId="current_estimated_date">
                          <Form.Label className="fw-bold">
                            Current Estimated Date for PP7
                          </Form.Label>
                          <Form.Control
                            className="mb-3"
                            type="date"
                            name="current_estimated_date"
                            onChange={handleChange}
                            defaultValue={moment
                              .utc(product.validator_checkout_date)
                              .format("YYYY-MM-DD")}
                          />
                        </Form.Group>
                      )}
                      {progressPoint?.est_completion_dates?.map((date, idx) => (
                        <Row key={`pp-est-act-date-${date.id}`}>
                          <Col>
                            <Form.Group controlId="resubmission_dates">
                              <Form.Label className="fw-bold">
                                {idx === 0
                                  ? `${progressPoint?.progress_point}. Submission`
                                  : `${progressPoint?.progress_point}${String.fromCharCode(64 + idx).toLowerCase()}. Resubmission`}
                              </Form.Label>
                              <Stack direction="horizontal">
                                <p className="mx-2">Estimated:</p>
                                <Form.Control
                                  className="mb-3"
                                  type="date"
                                  name={`${completionDateIds[idx]}_updated_est_completion`}
                                  onChange={handleChange}
                                  defaultValue={moment
                                    .utc(date?.estimated_date)
                                    .format("YYYY-MM-DD")}
                                />
                              </Stack>
                              {progressPoint?.submission_dates[idx] && (
                                <Stack direction="horizontal">
                                  <p className="mx-2">Actual:</p>
                                  <Form.Control
                                    className="mb-3"
                                    type="date"
                                    name={`${submissionDateIds[idx]}_updated_act_completion`}
                                    onChange={handleChange}
                                    defaultValue={moment
                                      .utc(
                                        progressPoint?.submission_dates[idx]
                                          ?.estimated_date,
                                      )
                                      .format("YYYY-MM-DD")}
                                  />
                                </Stack>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      ))}
                      {progressPoint?.est_completion_dates?.length > 0 &&
                        progressPoint.status === "In Progress" && (
                          <Row>
                            <Col>
                              <Form.Group controlId="current_estimated_date">
                                <Form.Label className="fw-bold">
                                  {`${progressPoint?.progress_point}${String.fromCharCode(65 + progressPoint.resubmission_counter).toLowerCase()}. Resubmission`}
                                </Form.Label>
                                <Stack direction="horizontal">
                                  <p className="mx-2">Estimated:</p>
                                  <Form.Control
                                    className="mb-3"
                                    type="date"
                                    name="current_estimated_date"
                                    onChange={handleChange}
                                    defaultValue={moment
                                      .utc(product.validator_checkout_date)
                                      .format("YYYY-MM-DD")}
                                  />
                                </Stack>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                    </Col>
                  </Row>
                  {files.length > 0 && (
                    <Row>
                      <p className="fw-bold form-title mt-3 mb-1">
                        ATTACH FILES
                      </p>
                    </Row>
                  )}
                  <div className="border p-3 mt-3">
                    <Row>
                      <h6>Progress Point Files</h6>
                      <Col xl={3} sm={12}>
                        <Form.Group
                          className="mb-4 text-start"
                          controlId="file_display_name"
                        >
                          <Form.Label className="small text-secondary">
                            Type
                          </Form.Label>
                          <Form.Select
                            name="file_display_name"
                            onChange={handleChange}
                          >
                            <option value=""></option>
                            {PP7_FILE_TYPES.map((type, idx) => (
                              <option value={type} key={idx}>
                                {type}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xl={3} sm={12}>
                        <Form.Group
                          className="mb-4 text-start"
                          controlId="file_public_status"
                        >
                          <Form.Label className="small text-secondary">
                            Document Visibility
                          </Form.Label>
                          <Form.Select
                            name="file_public_status"
                            onChange={handleChange}
                          >
                            <option value=""></option>
                            <option value="Public">Public</option>
                            <option value="Proprietary">Proprietary</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xl={6} sm={12}>
                        <Form.Group controlId="file_label">
                          <Form.Label className="small text-secondary">
                            File Label
                          </Form.Label>
                          <Form.Control
                            value={editedFileLabel}
                            type="text"
                            name="file_label"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={6} sm={12} className="mb-3">
                        <Form.Group controlId="file">
                          <Form.Label className="small text-secondary">
                            Attach Files
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="file"
                            onChange={handleChange}
                            disabled={
                              !formInfo.file_public_status ||
                              !formInfo.file_display_name
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10}>
                        {files?.length > 0 && (
                          <div className="border">
                            <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                            {files
                              .filter((file) => file?.active === true)
                              ?.map((file, idx) => (
                                <Row
                                  className="m-3 d-flex justify-content-around"
                                  key={idx}
                                >
                                  <Col sm={4}>{file.file_display_name}</Col>
                                  <Col sm={4}>{file.file_label}</Col>
                                  <Col sm={2}>
                                    {file.private ? "Proprietary" : "Public"}
                                  </Col>
                                  <Col
                                    sm={1}
                                    className="d-flex justify-content-center"
                                  >
                                    <Button
                                      variant="outline-primary"
                                      className="attachment-remove"
                                      onClick={() => removeFile(file, idx)}
                                    >
                                      X
                                    </Button>
                                  </Col>
                                </Row>
                              ))}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  {/* I think we need to talk about when exactly this needs to show and what files it should show */}
                  <Row>
                    <Col lg={12}>
                      <div className="mt-5">
                        <p className="fw-bold form-title mb-1">
                          FILES FOR CHECK-OUT PACKAGE:
                        </p>
                        <div className="border">
                          <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                          {/* only show checkout files for evaluator or validator */}
                          {checkoutFiles?.map((file, idx) => (
                            <Row className="m-3" key={idx}>
                              <Col>
                                <Form.Check
                                  type="checkbox"
                                  id={`${file.file_id}`}
                                  name="files"
                                  className="progress-point-checkboxes"
                                  defaultChecked={file?.file_source_type?.includes(
                                    "Validator Check Out",
                                  )}
                                  onClick={(e) =>
                                    handleAddCheckoutFile(e, file)
                                  }
                                  label={
                                    <Row>
                                      <Col lg={3}>
                                        <Button
                                          variant="link"
                                          className="text-bright-navy text-decoration-underline p-0"
                                          style={{
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                          }}
                                          onClick={() => downloadFile(file)}
                                        >
                                          {file.file_label
                                            ? file.file_label
                                            : file.file_display_name}
                                        </Button>
                                      </Col>
                                      <Col lg={3}>{file.file_name}</Col>
                                      <Col lg={3}>
                                        {file?.uploaded_on &&
                                          moment
                                            .utc(file.uploaded_on)
                                            .format("YYYY.MM.DD [at] HH:mm")}
                                      </Col>
                                      <Col lg={3}>
                                        {file.private
                                          ? "Proprietary"
                                          : "Public"}
                                      </Col>
                                    </Row>
                                  }
                                />
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <p className="fw-bold form-title mb-2">NOTES*</p>
                      <HTMLEditor
                        name="notes"
                        handleChange={handleChange}
                        setIsValid={() => {}}
                        isValid={true}
                        setEnforceFocusModal={setEnforceFocusModal}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row className="d-flex justify-content-end">
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                      setRejected(false);
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    onClick={handleSave}
                    className="square-button"
                  >
                    Save
                  </Button>
                </Col>
                {getSubmitButton()}
              </Row>
              {(rejected || progressPoint?.checkout_rejected_rationale) && (
                <Col lg={12} className="px-3">
                  <Form.Group controlId="checkout_rejected_rationale">
                    <p className="fw-bold form-title">
                      RATIONALE FOR REJECTION
                    </p>
                    <Form.Control
                      rows={3}
                      as="textarea"
                      name="checkout_rejected_rationale"
                      defaultValue={progressPoint?.checkout_rejected_rationale}
                      disabled={permissions.role_type !== "Validator"}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              )}
            </Container>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
