import moment from "moment";
import { Col, Row } from "react-bootstrap";

const EstAndActDates = ({ pp, getEstimatedCompletionDate }) => {
  const estDate = getEstimatedCompletionDate(pp.progress_point);

  return (
    <Row key={`dates-${pp.prog_id}-${pp.progress_point}`}>
      <Col className="p-0 d-flex justify-content-center">
        <p className="small-font m-0 p-0 text-secondary">
          {estDate
            ? moment
                .utc(getEstimatedCompletionDate(pp.progress_point))
                .format("YYYY.MM.DD")
            : "No Date Set"}{" "}
          (est.)
          <br />
          {pp?.submission_dates?.[0]?.estimated_date
            ? moment
                .utc(pp.submission_dates[0].estimated_date)
                .format("YYYY.MM.DD")
            : "No Date Set"}{" "}
          (act.)
        </p>
      </Col>
    </Row>
  );
};

export default EstAndActDates;
