import { Button, Popover, OverlayTrigger } from "react-bootstrap";

import StageTracker from "components/Dashboard/StageTracker/StageTracker";

const statusLookup = {
  Draft: "In Draft",
  Certified: "Completed",
};

const ProductStatusCell = ({ project }) => {
  const { status } = project;
  const buttonTitle = statusLookup[status] || status;

  return (
    <div style={{ width: "max-content" }}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        key="bottom"
        overlay={
          <Popover
            style={{
              minWidth: "200px",
              maxWidth: "600px",
              width: "max-content",
            }}
          >
            <Popover.Body>
              <StageTracker rowData={project} />
            </Popover.Body>
          </Popover>
        }
      >
        <Button
          variant="link"
          className="text-bright-navy small-font"
          style={{ width: "max-content" }}
        >
          {buttonTitle}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default ProductStatusCell;
