import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams, useNavigate, Link } from "react-router-dom";

import {
  useMfaConfirmMutation,
  useMfaActivateConfirmMutation,
} from "api/public/auth/auth.api";
import { MfaMethod, MfaPayload } from "api/public/auth/auth.types";
import { useAuth } from "hooks/useAuth";

const titleLookup = {
  sms_twilio: "Phone",
  email: "Email",
  app: "App",
} as const;

const MFAConfirm = () => {
  const [searchParams] = useSearchParams();
  const method = searchParams.get("mfa-method") as MfaMethod;
  const ephemeralToken = searchParams.get("ephemeral-token");

  const [mfaInfo, setMfaInfo] = useState<MfaPayload>({
    code: "000000",
    ephemeralToken: "",
  });

  const navigate = useNavigate();

  const { tempToken } = useAuth();

  const [triggerMfaConfirm, mfaConfirmResult] = useMfaConfirmMutation();
  const {
    isLoading: isConfirmLoading,
    isSuccess: isConfirmSuccess,
    isError: isConfirmError,
    error: confirmError,
  } = mfaConfirmResult;

  const [triggerMfaActivate, mfaActivateResult] =
    useMfaActivateConfirmMutation();

  const {
    isLoading: isActivateLoading,
    isSuccess: isActivateSuccess,
    isError: isActivateError,
    error: activateError,
    data: backupCodes,
  } = mfaActivateResult;

  useEffect(() => {
    if (!isConfirmSuccess) return;

    navigate("/login/success/", {
      state: { is_login: true },
    });
  }, [isConfirmSuccess, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMfaInfo({ ...mfaInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!ephemeralToken) {
      await triggerMfaActivate({
        code: mfaInfo.code,
        tempToken,
        method: method,
      });
    } else {
      await triggerMfaConfirm({
        code: mfaInfo.code,
        ephemeralToken: ephemeralToken,
      });
    }
  };

  const title = method ? titleLookup[method] : "";

  const isExistingUser = Boolean(ephemeralToken);

  const isLoading = isConfirmLoading || isActivateLoading;
  const isError = isConfirmError || isActivateError;
  const errorMessage =
    confirmError ||
    activateError ||
    "Something went wrong. Please try again later";

  const anotherMethodResendLink = isExistingUser
    ? `/login/resend-code/?ephemeral-token=${ephemeralToken}`
    : `/login/mfa-select`;

  const buttonText = isExistingUser ? "Login" : "Submit";

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Check Your {title}</Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="code">
            <Form.Label className="small text-secondary">MFA Code*</Form.Label>
            <Form.Control
              type="text"
              name="code"
              onChange={handleChange}
              required={true}
            />
          </Form.Group>

          {isError && <Alert variant="danger">{errorMessage.toString()}</Alert>}

          {isActivateSuccess && (
            <>
              <div className="backup-codes mb-4">
                <h5>Backup Codes</h5>
                {backupCodes.map((code) => (
                  <p key={code}>{code}</p>
                ))}
              </div>

              <Button
                variant="warning"
                onClick={() =>
                  navigate("/login/reset-password/", {
                    state: { is_continue: true },
                  })
                }
              >
                Continue
              </Button>
              <br />
              <br />
              <Button
                variant="warning"
                onClick={() => navigate("/login/mfa-select")}
              >
                Add Another Method
              </Button>
            </>
          )}

          {!isActivateSuccess && (
            <>
              <p className="text-secondary small">
                Please be patient. You will receive your code shortly.
              </p>
              <Link to={anotherMethodResendLink}>
                Choose Another Method/Resend Code
              </Link>
              <br />
              <Button
                variant="warning"
                disabled={isLoading}
                type={isLoading ? "button" : "submit"}
                className="mt-3"
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Login
                  </>
                ) : (
                  buttonText
                )}
              </Button>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAConfirm;
