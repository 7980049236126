import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";

const AddDetails = () => {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["temp_token"];
  const [userInfo, setUserInfo] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const method = searchParams.get("method");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const checkPhones = () => {
    if (
      userInfo?.phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        userInfo?.phone_number,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() || !checkPhones()) {
      e.stopPropogation();
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${userInfo.id}/`,
          userInfo,
          { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
        )
        .then((response) => {
          axios
            .post(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${method}/activate/`,
              { method: method },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              navigate(`/login/mfa-confirm?mfa-method=${method}`);
            })
            .catch((error) => {
              setFormErrors(error.response.data);
            });
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    }
  };

  useEffect(() => {
    const loadUser = () => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          const currentUser = response.data;
          let collapsedUserData = {};
          collapsedUserData.id = currentUser?.id;
          collapsedUserData.username = currentUser?.username;
          collapsedUserData.password = currentUser?.password;
          collapsedUserData.user_role = currentUser?.user_role?.role_id;
          collapsedUserData.account_group =
            currentUser?.account_group?.group_id;
          collapsedUserData.scheme = currentUser?.scheme?.sid;
          collapsedUserData.company = currentUser?.company?.org_id;
          collapsedUserData.creator = currentUser?.creator?.id;
          collapsedUserData.editor = currentUser?.editor?.id;
          setUserInfo(collapsedUserData);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    };
    loadUser();
  }, []);

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          You do not have a {method !== "sms_twilio" ? method : "phone"}{" "}
          asocciated with your account. Please enter one now
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          {method === "email" && (
            <Form.Group className="mb-4 text-start" controlId="username">
              <Form.Label className="small text-secondary">
                Email Address*
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                required={true}
              />
            </Form.Group>
          )}
          {method === "sms_twilio" && (
            <Form.Group className="mb-4 text-start" controlId="username">
              <Form.Label className="small text-secondary">
                Phone Number*
              </Form.Label>
              <Form.Control
                type="tel"
                name="phone_number"
                onChange={handleChange}
                required={true}
                isInvalid={errors.phone_number}
              />
            </Form.Group>
          )}
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddDetails;
