import moment from "moment";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { useDeleteNistCommentMutation } from "api/memberPortal/certReview/certReview.api";

const DeleteCommentModal = ({
  showDeleteModal,
  setShowDeleteModal,
  toDeleteComment,
}) => {
  const [enforceFocusModal] = useState(true);
  const [triggerDeleteNistComment] = useDeleteNistCommentMutation();

  const deleteComment = async (upid) => {
    await triggerDeleteNistComment(upid);
    setShowDeleteModal(false);
  };

  return (
    <Modal
      size="md"
      show={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
      enforceFocus={enforceFocusModal}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Delete Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">
          Are You Sure You Want to Delete this By: <br />
          {toDeleteComment.creator?.first_name}{" "}
          {toDeleteComment.creator?.last_name} on: <br />
          {toDeleteComment.entrydate &&
            moment
              .utc(toDeleteComment.entrydate)
              .format("YYYY.MM.DD [at] HHmm")}
        </h4>
        <div className="d-flex justify-content-center mt-4">
          <Button
            variant="primary"
            className="m-1"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="m-1"
            onClick={() => deleteComment(toDeleteComment.upid)}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteCommentModal;
