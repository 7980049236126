import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";

import {
  useIsMyNcrtQuery,
  useGetNistQuery,
  useIsNcrtTeamMemberQuery,
} from "api/memberPortal/certReview/certReview.api";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import NISTHeader from "./NISTHeader";
import NISTSideInfo from "./NISTSideInfo";
import NISTSummary from "./NISTSummary";

const CheckNISTPerms = ({ setOverlayActive, setOverlayText }) => {
  const { id } = useParams();

  const { permissions } = useAuth();

  // look more into isFetching vs isLoading
  const { data: isMyNcrt, isLoading } = useIsMyNcrtQuery(id, { skip: !id });

  if (isLoading) {
    return;
  }

  if (
    (permissions.role_type === "Lab" && isMyNcrt) ||
    permissions.role_type === "Validator" ||
    permissions.role_type === "NIAP"
  ) {
    return (
      <NISTDetails
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else {
    return <UnauthorizedView />;
  }
};

const NISTDetails = ({ setOverlayActive, setOverlayText }) => {
  const { id } = useParams();
  const { permissions, currentUser } = useAuth();

  const { data: certReview = {} } = useGetNistQuery(id, { skip: !id });

  const { data: isNCRTTeamMember = false } = useIsNcrtTeamMemberQuery(
    currentUser.id,
    {
      skip: !currentUser.id || permissions.role_type === "NIAP",
    },
  );

  const canEditNist = permissions.role_type === "NIAP" || isNCRTTeamMember;

  return (
    <Container fluid className="my-3">
      <Row>
        <Col sm={12} md={8} lg={9}>
          <NISTHeader certReview={certReview} canEditNist={canEditNist} />
          <NISTSummary certReview={certReview} canEditNist={canEditNist} />
        </Col>
        <Col sm={12} md={4} lg={3}>
          <NISTSideInfo
            certReview={certReview}
            isNCRTTeamMember={isNCRTTeamMember}
            canEditNist={canEditNist}
            permissions={permissions}
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CheckNISTPerms;
