import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "components/Products/Helper/functions";

export default function ValidatorRecommendPostingModal({
  show,
  handleClose,
  product,
  files,
  refreshPage,
  refetch,
}) {
  const [cookies] = useCookies();
  const user = useSelector((state) => state.user.value);
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [notes] = useState([]);

  const handleUpdateProgressPoint = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          product: product.product_id,
          progress_point: 7,
          status: "Completed",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status === "Update Already Exists") {
          refreshPage();
        } else {
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
              {
                product: product.product_id,
                progress_point: 8,
                status: "In Progress",
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              if (response.data.status === "Update Already Exists") {
                refreshPage();
              } else {
                const note = "Product Approved";
                const submittedBy = user.id;
                const noteType = "Product";
                const noteTypeId = product.product_id;
                createNote(note, submittedBy, noteType, noteTypeId);
                axios
                  .put(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
                    {
                      status: "Finalizing",
                      validator_posting_verifier: user.id,
                      validator_posting_date: new Date(
                        new Date().setMinutes(
                          new Date().getMinutes() -
                            new Date().getTimezoneOffset(),
                        ),
                      ),
                    },
                    {
                      withCredentials: true,
                      headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then(() => {
                    handleAlertAndMailer(csrfToken, authToken, {
                      alert_type_id: product?.product_id,
                      alert_type: "Product",
                      subject: `Posting Recommendation for VID${product?.v_id}`,
                      recipients: { to: ["NIAP Management"], cc: [] },
                      alert_text: `Posting Recommendation for VID${product?.v_id}`,
                    });
                    refetch();
                    handleClose();
                  })
                  .catch((error) => console.log(error));
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };

  const createNote = async (note, submittedBy, noteType, noteTypeId) => {
    const newNotes = notes.slice();
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          submitted_by: submittedBy,
          note_type: noteType,
          note_type_id: noteTypeId,
          submitted_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => newNotes.push({ ...response.data }))
      .catch((error) => alert("Unable to add note: " + error));
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>
          <h3 style={{ whiteSpace: "nowrap" }} className="text-bright-navy">
            RECOMMEND FOR POSTING
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Row>
                <p className="mb-1 fw-bold">Validator Checkout Files</p>
              </Row>
              <Col className="px-5 pt-3 pb-3 border border-gray-700">
                {files
                  ?.filter((f) =>
                    f?.file_source_type?.includes("Validator Check Out"),
                  )
                  ?.map((file, idx) => (
                    <Row key={idx}>
                      <p className="mb-1">{file?.file_name}</p>
                    </Row>
                  ))}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              {product?.cctl_checkout_signer && (
                <Row>
                  <Col sm={1}>
                    <CheckBoxIcon
                      style={{
                        color: "var(--bs-success)",
                      }}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <p>
                      Evaluator Release Form Completed on{" "}
                      {moment
                        .utc(product?.cctl_checkout_date)
                        .format("MM/DD/YYYY")}{" "}
                      by {product?.cctl_checkout_signer?.first_name}{" "}
                      {product?.cctl_checkout_signer?.last_name},{" "}
                      {product?.cctl_checkout_signer?.org?.name}
                    </p>
                  </Col>
                </Row>
              )}
              {product?.vendor_checkout_signer && (
                <Row>
                  <Col sm={1}>
                    <CheckBoxIcon
                      style={{
                        color: "var(--bs-success)",
                      }}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <p>
                      Vendor Release Form Completed on{" "}
                      {moment
                        .utc(product?.vendor_checkout_date)
                        .format("MM/DD/YYYY")}{" "}
                      by {product?.vendor_checkout_signer?.first_name}{" "}
                      {product?.vendor_checkout_signer?.last_name},{" "}
                      {product?.vendor_checkout_signer?.org?.name}
                    </p>
                  </Col>
                </Row>
              )}
              {product?.labs_cc_draft_cert_verifier && (
                <Row>
                  <Col sm={1}>
                    <CheckBoxIcon
                      style={{
                        color: "var(--bs-success)",
                      }}
                    />
                  </Col>
                  <Col>
                    <p>
                      Evaluator CC Draft Certificate Verified on{" "}
                      {moment
                        .utc(product?.labs_cc_draft_cert_date)
                        .format("MM/DD/YYYY")}{" "}
                      by {product?.labs_cc_draft_cert_verifier?.first_name}{" "}
                      {product?.labs_cc_draft_cert_verifier?.last_name},{" "}
                      {product?.labs_cc_draft_cert_verifier?.org?.name}
                    </p>
                  </Col>
                </Row>
              )}
              {product?.vendors_cc_draft_cert_verifier && (
                <Row>
                  <Col sm={1}>
                    <CheckBoxIcon
                      style={{
                        color: "var(--bs-success)",
                      }}
                    />
                  </Col>
                  <Col>
                    <p>
                      Vendor CC Draft Certificate Verified on{" "}
                      {moment
                        .utc(product?.vendor_cc_draft_cert_date)
                        .format("MM/DD/YYYY")}{" "}
                      by {product?.vendors_cc_draft_cert_verifier?.first_name}{" "}
                      {product?.vendors_cc_draft_cert_verifier?.last_name},{" "}
                      {product?.vendors_cc_draft_cert_verifier?.org?.name}
                    </p>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0 p-0">
        <div className="d-flex justify-content-end">
          <Button
            style={{ width: "max-content" }}
            className="m-3"
            onClick={handleUpdateProgressPoint}
          >
            Recommend to Post
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
