import moment from "moment";
import { Accordion, Container, Row, Col } from "react-bootstrap";

import { useTdVotesByIdQuery } from "api/memberPortal/voting/tdVotes/tdVotes.api";

const TechDecisionSideInfo = ({ techDecision }) => {
  const { data: votes = [] } = useTdVotesByIdQuery(techDecision.td_id, {
    skip: !techDecision.td_id,
    pollingInterval: 600_000, // 1 min
  });

  const showApprovalVotes = (vote) => {
    return votes.map((oneVote) => {
      if (oneVote.approved === vote) {
        return (
          <Row key={oneVote.id}>
            <p>
              {moment.utc(oneVote.vote_date).format("YYYY.MM.DD [at] HHmm")} by
              <br></br>
              {oneVote.voter.first_name} {oneVote.voter.last_name}
              {", "}
              {oneVote.voter.user_role.role_name}
            </p>
          </Row>
        );
      }
      return null;
    });
  };

  return (
    <div className="mt-3">
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Modification History</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  <Row>
                    <p className="text-secondary">Record Created:</p>
                  </Row>
                  <Row className="border-bottom">
                    <p>
                      {techDecision?.created_on &&
                        moment(techDecision?.created_on).format(
                          "YYYY.MM.DD [at] HHmm",
                        )}{" "}
                      by
                      <br></br>
                      {techDecision?.created_by?.first_name}{" "}
                      {techDecision?.created_by?.last_name}{" "}
                    </p>
                  </Row>
                  <Row>
                    <p className="text-secondary">Last Modified:</p>
                  </Row>
                  <Row className="border-bottom">
                    {techDecision?.modified_by && (
                      <p>
                        {techDecision?.modified_on &&
                          moment(techDecision.modified_on).format(
                            "YYYY.MM.DD [at] HHmm",
                          )}{" "}
                        by
                        <br></br>
                        {techDecision?.modified_by?.first_name}{" "}
                        {techDecision?.modified_by?.last_name}{" "}
                      </p>
                    )}
                  </Row>
                  <Row>
                    <p className="text-secondary">Published Date:</p>
                  </Row>
                  <Row>
                    {techDecision?.released_by && (
                      <p>
                        {techDecision?.released_on &&
                          moment(techDecision.released_on).format(
                            "YYYY.MM.DD [at] HHmm",
                          )}{" "}
                        by
                        <br></br>
                        {techDecision?.released_by?.first_name}{" "}
                        {techDecision.released_by?.last_name}{" "}
                      </p>
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Approvals</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <p className="text-secondary">Votes for YES:</p>
              </Row>
              {showApprovalVotes(true)}
              <Row className="border-top">
                <p className="text-secondary">Votes for NO:</p>
              </Row>
              {showApprovalVotes(false)}
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default TechDecisionSideInfo;
