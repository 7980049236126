import FlagIcon from "@mui/icons-material/Flag";

const ResubmissionCell = ({ project }) => {
  if (!project.is_resubmission) return null;

  return (
    <FlagIcon
      sx={{ color: "var(--bs-red)", fontSize: 20, textAlign: "center" }}
      data-testid="FlagIcon"
    />
  );
};
export default ResubmissionCell;
