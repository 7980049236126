export const TECH_TYPE_OPTIONS = {
  ANTI_VIRUS: "Anti-Virus",
  APPLICATION_SOFTWARE: "Application Software",
  BIOMETRICS: "Biometrics",
  BIOS_UPDATE: "BIOS Update",
  CERTIFICATE_AUTHORITY: "Certificate Authority",
  DBMS: "DBMS",
  DEGAUSSING_EQUIPMENT: "Degaussing Equipment",
  EMAIL_CLIENT: "Email Client",
  ENCRYPTED_COMM_DEVICES: "Encrypted Comm Devices",
  ENCRYPTED_STORAGE: "Encrypted Storage",
  ENTERPRISE_SECURITY_MANAGEMENT: "Enterprise Security Management",
  FIREWALL: "Firewall",
  GUARD: "Guard",
  HARDWARE_PLATFORM_AND_COMPONENTS: "Hardware Platform and Components",
  KEY_RECOVERY: "Key Recovery",
  MISCELLANEOUS: "Miscellaneous",
  MOBILE_CODE: "Mobile Code",
  MOBILITY: "Mobility",
  MULTI_FUNCTION_DEVICE: "Multi Function Device",
  MULTIPLE_DOMAIN_SOLUTION: "Multiple Domain Solution",
  NETWORK_ACCESS_CONTROL: "Network Access Control",
  NETWORK_DEVICE: "Network Device",
  NETWORK_ENCRYPTION: "Network Encryption",
  NETWORK_INTERFACE_CARD: "Network Interface Card",
  NETWORK_MANAGEMENT: "Network Management",
  NETWORK_SEPARATOR: "Network Separator",
  NETWORK_SWITCH: "Network Switch",
  OPERATING_SYSTEM: "Operating System",
  PERIPHERAL_SWITCH: "Peripheral Switch",
  PKI_KMI: "PKI/KMI",
  REDACTION_TOOL: "Redaction Tool",
  REMOTE_ACCESS: "Remote Access",
  RETRANSMISSION_DEVICE: "Retransmission Device",
  ROUTER: "Router",
  SECURE_MESSAGING: "Secure Messsaging",
  SENSITIVE_DATA_PROTECTION: "Sensitive Data Protection",
  SHARING_SWITCH: "Sharing Switch",
  SIP_SERVER: "SIP Server",
  SMART_CARD: "Smart Card",
  SYSTEM_ACCESS_CONTROL: "System Access Control",
  TOKENS: "Tokens",
  TRAFFIC_MONITORING: "Traffic Monitoring",
  USB_FLASH_DRIVE: "USB Flashdrive",
  VIRTUAL_PRIVATE_NETWORK: "Virtual Private Network",
  VIRTUALIZATION: "Virtualization",
  VOIP: "VoIP",
  WEB_BROWSER: "Web Browser",
  WEB_SERVER: "Web Server",
  WIRELESS_LAN: "Wireless LAN",
  WIRELESS_MONITORING: "Wireless Monitoring",
  WIRELESS_PAN: "Wireless PAN",
};

export const TECH_TYPE_OPTIONS_ARRAY = Object.values(TECH_TYPE_OPTIONS);
