import { baseApi } from "api/BaseApi";

import { TqVotingPayload, Vote, VotingUpdatePayload } from "../voting.types";

const TQ_VOTING_BASE_QUERY = "technical-query/voting";

const tqVotingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    tqVotesById: build.query<Vote[], number>({
      query: (tqId) => ({
        url: `${TQ_VOTING_BASE_QUERY}/tq_votes_by_tq_id/`,
        params: { tq_id: tqId },
      }),
      providesTags: ["TQ_VOTES"],
    }),

    deleteTqVote: build.mutation<void, number>({
      query: (userId) => ({
        url: `${TQ_VOTING_BASE_QUERY}/${userId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TQ_VOTES"],
    }),

    updateTqVote: build.mutation<void, VotingUpdatePayload>({
      query: ({ userId, body }) => ({
        url: `${TQ_VOTING_BASE_QUERY}/${userId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TQ_VOTES"],
    }),

    addTqVote: build.mutation<void, TqVotingPayload>({
      query: (body) => ({
        url: `${TQ_VOTING_BASE_QUERY}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TQ_VOTES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useTqVotesByIdQuery,
  useDeleteTqVoteMutation,
  useUpdateTqVoteMutation,
  useAddTqVoteMutation,
} = tqVotingApi;
