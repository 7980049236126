import { useCallback, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { removeRole } from "../../app/role";
import { removeUser } from "../../app/user";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const [_cookie, _setCookies, removeCookie] = useCookies();
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = useCallback(() => {
    if (location.pathname.includes("/login")) {
      removeCookie("temp_token");
      navigate("/login");
    } else {
      removeCookie("auth_token");
      removeCookie("old_auth_token"); // also clear emulation
      dispatch(removeRole());
      dispatch(removeUser());
      navigate("/logout");
    }
  }, [dispatch, location.pathname, navigate, removeCookie]);

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = useCallback(
    (time) => {
      timerRef.current = setTimeout(() => {
        // clears any pending timer.
        resetTimer();
        // Listener clean up. Removes the existing event listener from the window
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        // logs out user
        logoutAction();
      }, time); // 10000ms = 10secs. You can change the time.
    },
    [logoutAction],
  );

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const restartTimer = useCallback(() => {
    if (location.pathname.includes("/login")) {
      resetTimer();
      handleLogoutTimer(300_000); // 5 min
    } else {
      resetTimer();
      handleLogoutTimer(28_800_000); // 8 Hours
    }
  }, [handleLogoutTimer, location.pathname]);

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, restartTimer);
    });

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, restartTimer);
      });
      resetTimer(); // Make sure the timer is cleared
    };
  }, [restartTimer]);

  return children;
};

export default AppLogout;
