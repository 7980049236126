import TeamIcon from "@mui/icons-material/GroupAdd";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

import { useAuth } from "hooks/useAuth";

const ValidationTeamAction = ({
  project,
  setProduct,
  setShowAMValTeamModal,
  setShowValTeamModal,
}) => {
  const { permissions } = useAuth();

  if (permissions.role_type !== "NIAP") return null;

  return (
    <MenuItem
      onClick={() => {
        setProduct(project);
        if (project.status === "In Assurance Maintenance") {
          setShowAMValTeamModal(true);
        } else {
          setShowValTeamModal(true);
        }
      }}
    >
      <ListItemIcon>
        <TeamIcon color="action" />
      </ListItemIcon>
      <ListItemText> Validation Team </ListItemText>
    </MenuItem>
  );
};

export default ValidationTeamAction;
