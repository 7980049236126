import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

import "../ProgressPointModal.css";
import HTMLEditor from "components/UI/HTMLEditor";
import { useAuth } from "hooks/useAuth";

export default function ProgressPointEditModal8({
  show,
  handleClose,
  product,
  progressPoint,
  isEdit,
  refetch,
  setRefetchNotes,
}) {
  const { authToken, csrfToken, currentUser } = useAuth();
  const [formInfo, setFormInfo] = useState({});
  const [eventId, setEventId] = useState();
  const [statusId, setStatusId] = useState();
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormInfo({});
  };

  const handleUpdateProgressPoint = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        console.log("updated progress point successfully");
      })
      .catch((error) => console.log(error));
  };

  const handleSave = (e) => {
    e.preventDefault();
    let data = {};

    const newDateObjects = Object.keys(formInfo).filter(
      (key) =>
        key.includes("new_act_completion") ||
        key.includes("new_est_completion"),
    );

    if (newDateObjects.length > 0) {
      data["new_dates"] = newDateObjects.map((key) => ({
        [key.split("_")[0]]: formInfo[key],
      }));
    }
    handleUpdateProgressPoint(data);
    updateProduct();
  };

  const handleAddNote = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: formInfo?.notes,
          note_type: "product",
          note_type_id: product?.product_id,
          submitted_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          submitted_by: currentUser.id,
          progress_point: progressPoint.progress_point,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setRefetchNotes(response.data.note_id))
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const updateProduct = async (data) => {
    const submittedData = new FormData();
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        submittedData.append(key, value);
      }
    } else {
      submittedData.append("is_resubmission", product.isResubmission || "");
      submittedData.append("ear_required", product.ear_required || "");
      submittedData.append(
        "ear_previously_submitted",
        product.ear_previously_submitted || "",
      );
      submittedData.append("sync_required", product.sync_required || "");
      submittedData.append("post_certificate", product.post_certificate || "");
      if (formInfo.completion_date) {
        submittedData.append(
          "pcl_posting_date",
          new Date(formInfo?.completion_date).toJSON(),
        );
        createCalendarEvent(new Date(`${formInfo?.completion_date}T00:00`));
      } else {
        submittedData.append(
          "pcl_posting_date",
          new Date(product.pcl_posting_date).toJSON(),
        );
      }

      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
          submittedData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          if (formInfo.notes) {
            handleAddNote();
          }
          handleClose(response.data);
          refetch();
        })
        .catch((error) => console.log("Unable to update product: ", error));
    }
  };

  const createCalendarEvent = (startDate) => {
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);
    const title = `VID${product?.v_id} PCL POSTING - PROGRESS POINT 8 Estimated Completion`;
    const requestData = {
      title: title,
      startDate: startDate,
      endDate: endDate,
      statusId: statusId,
      allDay: true,
      productId: product?.product_id,
    };

    if (eventId) {
      //Checks if event already exists
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${eventId}/`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) =>
          console.log("Unable to update Calendar event: ", error),
        );
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`,
          requestData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) =>
          console.log("Unable to create Calendar event: ", error),
        );
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        let currStatus = response.data?.filter((status) =>
          status.text.includes(progressPoint.progress_point),
        );
        setStatusId(currStatus[0]?.id);
        axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_event_by_status_and_product/?statusId=${currStatus[0]?.id}&productId=${product?.product_id}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            setEventId(response.data.id);
          })
          .catch(() => console.log("Unable to get Calendar Event."));
      })
      .catch(() => console.log("Unable to get Calendar statuses."));
  }, [progressPoint.progressPoint]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose();
        }}
        enforceFocus={enforceFocusModal}
      >
        <Form onReset={handleReset} id="progress-point-form">
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
              <h3 className="text-bright-navy">
                PCL POSTING - PROGRESS POINT 8
                {isEdit ? (
                  " EDIT"
                ) : ["Not Started", "Est. Completion Date Set"]?.includes(
                    progressPoint?.status,
                  ) ? (
                  " INITIATE"
                ) : progressPoint?.status === "In Progress" ? (
                  " COMPLETE"
                ) : (
                  <></>
                )}{" "}
                for VID: {product.v_id}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Row className="mt-3">
                    <Col sm={5} mb={2}>
                      <Form.Group controlId="completion_date">
                        <Form.Label className="fw-bold">
                          Current Estimated Date for PP
                          {progressPoint?.progress_point}
                        </Form.Label>
                        <Form.Control
                          className="mb-3"
                          type="date"
                          name="completion_date"
                          onChange={handleChange}
                          defaultValue={moment
                            .utc(product.pcl_posting_date)
                            .format("YYYY-MM-DD")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <p className="fw-bold form-title mb-2">NOTES*</p>
                      <HTMLEditor
                        name="notes"
                        handleChange={handleChange}
                        setIsValid={() => {}}
                        isValid={true}
                        setEnforceFocusModal={setEnforceFocusModal}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row className="d-flex justify-content-end">
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    onClick={handleSave}
                    className="square-button"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
