// eslint cant find this import for some reason, but it is directly from the docs
// eslint-disable-next-line
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { baseApi } from "api/BaseApi";
import {
  hasArrayProp,
  hasStringProp,
  normalizeErrorMessages,
} from "api/common/utils/rtkq.utils";
import {
  removeAllCookies,
  removeCsrfToken,
  removeTempToken,
  setAuthToken,
  setCsrfToken,
  setTempToken,
} from "utils/cookies";

import {
  LoginPayload,
  LoginResponse,
  MfaActivateConfirmPayload,
  MfaActivateConfirmResponse,
  MfaActivatePayload,
  MfaActivateResponse,
  MfaAuthResponse,
  MfaPayload,
  RawAuthResponse,
  TempTokenResponse,
} from "./auth.types";

const extractMfaBaseQueryError = (error: FetchBaseQueryError): string => {
  if (hasStringProp(error.data, "error")) {
    return (error.data as { error: string }).error;
  }

  if (hasStringProp(error.data, "detail")) {
    return (error.data as { detail: string }).detail;
  }

  if (hasArrayProp(error.data, "ephemeral_token")) {
    // Something went wrong with token generation.
    // This is not something the user did wrong. Trying again may resolve it
    return "Something went wrong. Please try logging in again";
  }

  if (hasArrayProp(error.data, "code")) {
    // The code does not match
    return (error.data as { code: [string] }).code[0];
  }

  // We don't really know what happened so we return a generic message
  return "Something went wrong. Please double check your code or try again later.";
};

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginPayload>({
      queryFn: async (
        credentials, // LoginPayload
        api, // unused
        extraOptions, // unused
        baseQuery, // use this to fetch
      ) => {
        const defaultErrorMessage =
          "Something went wrong. Please try again later.";

        try {
          removeAllCookies();

          const { data: loginData, error: loginError } = await baseQuery({
            url: "auth/login/",
            method: "POST",
            body: credentials,
            credentials: "include",
          });

          if (loginError) {
            console.error(loginError);
            return {
              error: loginError,
            };
          }

          const {
            auth_token: authToken,
            ephemeral_token: ephemeralToken,
            method: mfaMethod,
          } = loginData as RawAuthResponse;

          if (authToken) {
            // The user has not yet selected an MFA method so we need to set that up
            // NOTE: This auth token is returned from Django not from Trench
            setTempToken(authToken);

            return {
              data: { type: "setup", authToken },
            };
          }

          if (ephemeralToken) {
            // The user has an MFA and we generate a temp_token to use for MFA
            try {
              const { data: tempTokenData, error: tempTokenError } =
                await baseQuery({
                  url: "temp-token/",
                  method: "POST",
                  body: { ...credentials },
                  credentials: "include",
                });

              if (tempTokenError) {
                console.error("error generating temp token", tempTokenError);

                return { error: tempTokenError };
              }

              const { token: tempToken } = tempTokenData as TempTokenResponse;
              setTempToken(tempToken);

              return {
                data: {
                  type: "existing-user",
                  ephemeralToken,
                  mfaMethod,
                },
              };
            } catch (error) {
              console.error(error);

              return {
                error: {
                  status: "CUSTOM_ERROR",
                  error: defaultErrorMessage,
                },
              };
            }
          }

          return {
            error: {
              status: "CUSTOM_ERROR",
              error: defaultErrorMessage,
            },
          };
        } catch (error) {
          console.error("Authentication Error Occurred", error);

          return {
            error: {
              status: "CUSTOM_ERROR",
              error: defaultErrorMessage,
            },
          };
        }
      },
    }),

    mfaConfirm: build.mutation<MfaAuthResponse, MfaPayload>({
      query: ({ code, ephemeralToken: ephemeral_token }) => ({
        url: "auth/login/code/",
        method: "POST",
        body: { code, ephemeral_token },
        credentials: "include",
      }),

      onQueryStarted: async (_payload, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          removeTempToken();
          setAuthToken(data.auth_token);
        } catch (error) {
          console.error("Authentication failed. Cookies not set:", error);
        }
      },

      transformErrorResponse: (error) => {
        return normalizeErrorMessages(error, extractMfaBaseQueryError);
      },
    }),

    mfaActivate: build.mutation<string, MfaActivatePayload>({
      query: ({ method, tempToken }) => ({
        url: `auth/${method}/activate/`,
        method: "POST",
        credentials: "include",
        headers: { Authorization: `Token ${tempToken}` },
      }),

      transformResponse: (response: MfaActivateResponse) => {
        return response.details;
      },
    }),

    mfaActivateConfirm: build.mutation<string[], MfaActivateConfirmPayload>({
      query: ({ code, method, tempToken }) => ({
        url: `auth/${method}/activate/confirm/`,
        method: "POST",
        body: { code },
        credentials: "include",
        headers: { Authorization: `Token ${tempToken}` },
      }),
      transformErrorResponse: (error) => {
        return normalizeErrorMessages(error, extractMfaBaseQueryError);
      },
      transformResponse: (response: MfaActivateConfirmResponse) => {
        return response.backup_codes;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useLoginMutation,
  useMfaConfirmMutation,
  useMfaActivateMutation,
  useMfaActivateConfirmMutation,
} = authApi;
