import VisibilityIcon from "@material-ui/icons/Visibility";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const ViewAction = ({ project }) => {
  return (
    <MenuItem component={Link} to={`details/${project.product_id}`}>
      <ListItemIcon>
        <VisibilityIcon color="action" />
      </ListItemIcon>
      <ListItemText> View </ListItemText>
    </MenuItem>
  );
};

export default ViewAction;
