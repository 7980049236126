import { useState } from "react";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";

import ProgressPointsPopoverDates from "./SubmissionDates.jsx";

const SubmissionButton = ({ pp, product }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleToggle = () => setShowPopover(!showPopover);

  return (
    <OverlayTrigger
      show={showPopover}
      trigger="click"
      rootClose
      placement="bottom"
      overlay={
        <Popover>
          <Popover.Body>
            <ProgressPointsPopoverDates pp={pp} product={product} />
          </Popover.Body>
        </Popover>
      }
    >
      <div className="d-flex justify-content-center">
        <Button
          variant="link"
          size="md"
          className="text-center p-0 m-0 text-bright-blue"
          onClick={handleToggle}
        >
          Submissions
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default SubmissionButton;
