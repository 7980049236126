import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
  useUpdateNistCommentMutation,
  useAddNistCommentMutation,
} from "api/memberPortal/certReview/certReview.api";

import HTMLEditor from "../../../UI/HTMLEditor";

const NISTCommentModal = ({ show, onHide, certReview, selectedUpdate }) => {
  const [newComment, setNewComment] = useState({});
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const [triggerUpdateNistComment] = useUpdateNistCommentMutation();
  const [triggerAddNistComment] = useAddNistCommentMutation();

  const handleChange = (event) => {
    setNewComment({ ...newComment, [event.target.name]: event.target.value });
  };

  const handleReset = () => {
    setNewComment({});
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      await triggerUpdateNistComment({
        commentId: selectedUpdate?.upid,
        body: {
          ...newComment,
        },
      }).unwrap();
      onHide();
    } catch (error) {
      console.log("Error updating comment", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await triggerAddNistComment({
        ...newComment,
        crid: certReview?.crid,
        pid: certReview?.pid?.product_id,
      }).unwrap();
      onHide();
    } catch (error) {
      console.log("Error adding comment", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} enforceFocus={enforceFocusModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          {selectedUpdate?.upid ? "Update" : "Add"} Response to NIST Draft for
          VID {certReview?.pid?.v_id}
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="note">
            <Form.Label>Comment</Form.Label>
            <HTMLEditor
              id="note"
              name="note1"
              handleChange={handleChange}
              value={newComment?.note1}
              defaultValue={selectedUpdate?.note1}
              setIsValid={() => {}}
              isValid={true}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" onClick={handleReset} variant="primary">
            Reset
          </Button>
          <Button onClick={onHide} variant="danger">
            Cancel
          </Button>
          {selectedUpdate?.upid ? (
            <Button onClick={handleUpdate} variant="success">
              Update
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit} variant="success">
              Post
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NISTCommentModal;
