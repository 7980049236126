import { Cookies } from "react-cookie";

const cookies = new Cookies();

// Temp Token
export const setTempToken = (token: string): void => {
  cookies.set("temp_token", token, { path: "/", secure: true });
};

export const removeTempToken = (): void => {
  cookies.remove("temp_token");
};

// CSRF Token
export const getCsrfToken = (): string => {
  return cookies.get("csrftoken");
};

export const setCsrfToken = (token: string): void => {
  cookies.set("csrftoken", token, { path: "/", secure: true });
};

export const removeCsrfToken = (): void => {
  return cookies.remove("csrftoken");
};

// Auth Token
export const getAuthToken = (): string => {
  return cookies.get("auth_token");
};

export const setAuthToken = (token: string): void => {
  cookies.set("auth_token", token, { path: "/", secure: true });
};

export const removeAuthToken = (): void => {
  cookies.remove("auth_token");
};

// Global
export const removeAllCookies = (): void => {
  Object.keys(cookies.getAll()).forEach((cookie) => {
    cookies.remove(cookie);
  });
};
