import { Row, Col, Container } from "react-bootstrap";

import AlertsModule from "../AlertsModule";
import NIAPMetricsGroup from "../Metrics/NIAPMetricsGroup";
import NIAPProductsModule from "../NIAPProductsModule/NIAPProductsModule";
import NIAPTabsModule from "../NIAPTabsModule/NIAPTabsModule";

export default function NIAPDashboard({
  setOverlayActive,
  setOverlayText,
  roleName,
}) {
  return (
    <Container size="md" className="my-4" fluid>
      <NIAPMetricsGroup />
      <Col className="mx-2">
        <Row className="mb-4">
          <NIAPTabsModule />
        </Row>
        <Row className="mb-4">
          {roleName === "Database Manager" && (
            <NIAPProductsModule
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
            />
          )}
        </Row>
        <Row className="mb-4">
          <AlertsModule />
        </Row>
      </Col>
    </Container>
  );
}
