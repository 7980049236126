import DeleteIcon from "@mui/icons-material/Delete";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

import { useAuth } from "hooks/useAuth";

const DeleteDraftAction = ({ project, setProduct, setShowDeleteModal }) => {
  const { permissions } = useAuth();

  if (project.status !== "Draft") return null;
  if (permissions.role_type !== "NIAP") return null;

  return (
    <MenuItem
      onClick={() => {
        setProduct(project);
        setShowDeleteModal(true);
      }}
    >
      <ListItemIcon>
        <DeleteIcon color="action" />
      </ListItemIcon>
      <ListItemText> Delete Draft </ListItemText>
    </MenuItem>
  );
};

export default DeleteDraftAction;
