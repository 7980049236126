import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Form } from "react-bootstrap";

import {
  useGetAllNistStatusesQuery,
  useUpdateNistMutation,
} from "api/memberPortal/certReview/certReview.api";
import { getCurrentDate } from "utils/timeUtils";

const StatusEditableField = ({ value, canEditNist, certReview }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});

  const { data: statuses } = useGetAllNistStatusesQuery();
  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = (event) => {
    event.preventDefault();
    let data = {
      ...updatedNist,
    };

    let found = statuses?.find((element) => element.stid === updatedNist.stid);
    let statusName = found ? found.status : null;
    if (
      [
        "Rejected - Revision Required",
        "Approved",
        "Rejected, revisions needed",
      ]?.includes(statusName)
    ) {
      data["enddate"] = getCurrentDate();
    }
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...data,
      },
    });
    setIsEditing(false);
  };

  return canEditNist ? (
    isEditing ? (
      <Form className="mb-3" style={{ display: "flex", alignItems: "center" }}>
        <Form.Group controlId="note">
          <Form.Select
            name="stid"
            onChange={(e) =>
              setUpdatedNist({ ...updatedNist, stid: e.target.value })
            }
            defaultValue={certReview?.stid?.stid}
          >
            <option value="">Select a Status</option>
            {statuses.map((status, idx) => (
              <option key={idx} value={status.stid}>
                {status.status}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <span onClick={handleSave} style={{ cursor: "pointer" }}>
          <SaveIcon style={{ marginLeft: "8px" }} />
        </span>
      </Form>
    ) : (
      <p onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
        {value}
        <EditIcon style={{ marginLeft: "8px" }} />
      </p>
    )
  ) : (
    <p>{value}</p>
  );
};

export default StatusEditableField;
