import { calculateActiveDaysInProgress } from "utils/timeUtils";

const DaysInProgressCell = ({ project }) => {
  const daysInProgress = calculateActiveDaysInProgress(project);
  const displayText = daysInProgress > 0 ? daysInProgress : "Pending";

  return <p>{displayText}</p>;
};

export default DaysInProgressCell;
