import moment from "moment";
import { Modal, Button } from "react-bootstrap";

import { useDeleteNoteMutation } from "api/memberPortal/notes/notes.api";

const NistDeleteNoteModal = ({
  toDeleteNote,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const [triggerDeleteNote] = useDeleteNoteMutation();

  const deleteNote = async (note_id) => {
    try {
      await triggerDeleteNote(note_id).unwrap();
      setShowDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      size="md"
      show={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Delete Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">
          Are You Sure You Want to Delete this By: <br />
          {toDeleteNote.submitted_by?.first_name}{" "}
          {toDeleteNote.submitted_by?.last_name} on: <br />
          {toDeleteNote.submitted_on &&
            moment(toDeleteNote.submitted_on).format("YYYY.MM.DD [at] HHmm")}
        </h4>
        <div className="d-flex justify-content-center mt-4">
          <Button
            variant="primary"
            className="m-1"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="m-1"
            onClick={() => deleteNote(toDeleteNote.note_id)}
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NistDeleteNoteModal;
