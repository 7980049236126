import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useGetNistProductPPsQuery } from "api/memberPortal/certReview/certReview.api";

const PPViewField = ({ certReview }) => {
  const { data: productPPs } = useGetNistProductPPsQuery(
    certReview?.pid?.product_id,
  );

  return (
    <>
      {productPPs?.map((projpp) => (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          overlay={
            <Popover>
              <Popover.Body>{projpp?.pp__pp_name}</Popover.Body>
            </Popover>
          }
          key={projpp?.pp__pp_id}
        >
          <Link
            to={`/community/protectionprofiles/details/${projpp?.pp__pp_id}`}
            className="text-secondary"
          >
            <p className="py-0 my-0">{projpp?.pp__pp_short_name}</p>
          </Link>
        </OverlayTrigger>
      ))}
    </>
  );
};

export default PPViewField;
