import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton, Menu } from "@mui/material";
import { useState } from "react";

import DeleteDraftAction from "./actions/Delete.action";
import EcrCommentsAction from "./actions/EcrComments.action";
import FileActions from "./actions/Files.action";
import ValidationTeamAction from "./actions/ValidationTeam.action";
import ViewAction from "./actions/View.action";

// TODO: This pattern is based on the MUI Actions button in PFAActions.cell.jsx.
// TODO: We should refactor into reusable components now
const ProductTableActionsCell = ({
  project,
  setProduct,
  setShowDeleteModal,
  setShowAMValTeamModal,
  setShowValTeamModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = `actions-menu-${project.v_id || project.product_id}`;
  return (
    <>
      <IconButton
        id={`actions-menu-button-${project.v_id || project.product_id}`}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": menuId,
        }}
      >
        <DeleteDraftAction
          project={project}
          setProduct={setProduct}
          setShowDeleteModal={setShowDeleteModal}
        />
        <ViewAction project={project} />
        <EcrCommentsAction project={project} />
        <FileActions project={project} />
        <ValidationTeamAction
          project={project}
          setProduct={setProduct}
          setShowAMValTeamModal={setShowAMValTeamModal}
          setShowValTeamModal={setShowValTeamModal}
        />
      </Menu>
    </>
  );
};
export default ProductTableActionsCell;
