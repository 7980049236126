import { useState } from "react";

import { useGetNistNotesQuery } from "api/memberPortal/notes/notes.api";
import { useAuth } from "hooks/useAuth";

import NistNotesForm from "./NistNotesForm";
import Note from "./Note";

export default function NISTNotes({ item, id }) {
  const { currentUser } = useAuth();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [inResponseTo, setInResponseTo] = useState();
  const [expandAll, setExpandAll] = useState(false);

  const openNoteModal = (replyTo = null, note = null) => {
    setInResponseTo(replyTo);
    setCurrentNote(note);
    setShowNoteModal(true);
  };

  const closeNoteModal = () => {
    setShowNoteModal(false);
  };

  const { data: notes, isLoading } = useGetNistNotesQuery(id, { skip: !id });
  if (isLoading) return;

  const isCurrentUsersNote = (note) => {
    return note.submitted_by.id === currentUser.id;
  };

  return (
    <>
      {notes.length > 0 && (
        <div>
          <button
            className="btn btn-primary d-inline-block w-sm-100"
            style={{ minWidth: 10 }}
            onClick={() => setExpandAll(!expandAll)}
          >
            Expand All
          </button>
        </div>
      )}
      <div className="text-end">
        <button
          className="btn btn-link d-inline-block"
          style={{ minWidth: 10 }}
          onClick={() => openNoteModal()}
        >
          Add
        </button>
      </div>
      {notes.map((note) => (
        <Note
          key={note.note_id}
          note={note}
          openNoteModal={openNoteModal}
          isCurrentUsersNote={isCurrentUsersNote}
          expandAll={expandAll}
        />
      ))}
      <NistNotesForm
        show={showNoteModal}
        currentNote={currentNote}
        item={item}
        handleCloseNotesForm={closeNoteModal}
        replyTo={inResponseTo}
        id={id}
      />
    </>
  );
}
