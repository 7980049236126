import RateReviewIcon from "@mui/icons-material/RateReview";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const EcrCommentsAction = ({ project }) => {
  return (
    <MenuItem component={Link} to={`/community/ecr-comments/${project.v_id}`}>
      <ListItemIcon>
        <RateReviewIcon color="action" />
      </ListItemIcon>
      <ListItemText> ECR Comments </ListItemText>
    </MenuItem>
  );
};

export default EcrCommentsAction;
