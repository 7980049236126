import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";
import { useState } from "react";
import { Form } from "react-bootstrap";

import { useUpdateNistMutation } from "api/memberPortal/certReview/certReview.api";

const RcvdEditableField = ({ value, canEditNist, certReview }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});

  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = () => {
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...updatedNist,
      },
    });
    setIsEditing(false);
  };

  return canEditNist ? (
    isEditing ? (
      <Form className="mb-3" style={{ display: "flex", alignItems: "center" }}>
        <Form.Group controlId="note">
          <Form.Control
            type="date"
            name="rcvd"
            defaultValue={moment.utc(certReview?.rcvd).format("YYYY-MM-DD")}
            onChange={(e) => {
              setUpdatedNist({
                ...updatedNist,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </Form.Group>
        <span onClick={handleSave} style={{ cursor: "pointer" }}>
          <SaveIcon style={{ marginLeft: "8px" }} />
        </span>
      </Form>
    ) : (
      <p onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
        {value}
        <EditIcon style={{ marginLeft: "8px" }} />
      </p>
    )
  ) : (
    <p>{value}</p>
  );
};

export default RcvdEditableField;
