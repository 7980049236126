// examples lifted from docs at
// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling

// eslint cant find this import for some reason, but it is directly from the docs
// eslint-disable-next-line
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return typeof error === "object" && error != null && "status" in error;
};

/**
 * Type predicate to narrow an unknown error to a native Js error
 */
export const isNativeError = (error: unknown): error is Error =>
  error instanceof Error;

/**
 * Type predicate to narrow an unknown error to an object with a named string property
 */
export const hasStringProp = (object: unknown, prop: string): boolean => {
  return (
    typeof object === "object" &&
    object != null &&
    prop in object &&
    typeof (object as any)[prop] === "string"
  );
};

export const extractStringProp = (object: unknown, prop: string) => {
  if (hasStringProp(object, prop)) {
    // @ts-ignore - we can safely ignore object is of type unknown here because we checked for it in the if
    return object[prop];
  }
  return undefined;
};

/**
 * Type predicate to narrow an unknown error to an object with a named string property
 */
export const hasArrayProp = (object: unknown, prop: string): boolean => {
  return (
    typeof object === "object" &&
    object != null &&
    prop in object &&
    Array.isArray((object as any)[prop])
  );
};

/**
 * Type predicate to narrow an unknown error to an object with a string 'details' property
 */
export const isErrorWithDetails = (
  error: unknown,
): error is { details: string } => {
  return hasStringProp(error, "details");
};

type normalizeErrorFunction = (_error: FetchBaseQueryError) => string;

export const normalizeErrorMessages = (
  error: unknown,
  formatBaseQueryError: normalizeErrorFunction,
) => {
  if (isFetchBaseQueryError(error)) {
    return formatBaseQueryError(error);
  }

  if (isErrorWithDetails(error)) {
    return error.details;
  }

  if (isNativeError(error)) {
    return error.message;
  }
};
