import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Route, Routes, useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import AddDetails from "./AddMethodDetails";
import CheckEmail from "./CheckEmail";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import MFAConfirm from "./LoginMFAConfirm";
import MFADisplayQR from "./LoginMFADisplayQR";
import MFAselect from "./LoginMFASelect";
import ResendCode from "./LoginResendCode";
import LoginSuccess from "./LoginSuccess";
import ResetPassword from "./ResetPassword";
import ResetSuccess from "./ResetSuccess";
import "./Login.css";

const Login = () => {
  const { authToken, permissions: userRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole.role_type && authToken) {
      if (userRole.role_type !== "External") navigate("/community/");
      else navigate("/");
    }
  }, [authToken, userRole, navigate]);

  return (
    <div className="login-page">
      {(!authToken || !userRole.role_type) && (
        <Container>
          <Routes>
            <Route path="" element={<LoginForm />} />
            <Route path="mfa-select" element={<MFAselect />} />
            <Route path="resend-code" element={<ResendCode />} />
            <Route path="mfa-confirm" element={<MFAConfirm />} />
            <Route path="mfa-qr" element={<MFADisplayQR />} />
            <Route path="success" element={<LoginSuccess />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="check-email" element={<CheckEmail />} />
            <Route path="add-details" element={<AddDetails />} />
            <Route path="reset-password/:token?" element={<ResetPassword />} />
            <Route path="reset-success" element={<ResetSuccess />} />
          </Routes>
        </Container>
      )}
    </div>
  );
};

export default Login;
