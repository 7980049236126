import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useDeleteNistMutation } from "api/memberPortal/certReview/certReview.api";
import DeleteModal from "components/UI/DeleteModal";
import { useAuth } from "hooks/useAuth";

const NistDeleteButton = ({ certReview, setAlert }) => {
  const { permissions } = useAuth();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const [deleteNist] = useDeleteNistMutation();

  const handleDeleteNist = async () => {
    try {
      await deleteNist(certReview.crid);
      handleCloseConfirmationModal();
      navigate("/community/nist/");
    } catch (error) {
      console.log("Unable to delete NIST: " + error);
      setAlert({
        message: "Unable to delete NIST. Please try again.",
        variant: "danger",
      });
    }
  };

  const canDeleteNist = permissions.role_type === "NIAP";

  return (
    canDeleteNist && (
      <>
        <DeleteModal
          show={showConfirmationModal}
          handleClose={handleCloseConfirmationModal}
          type="Nist Certificate Review"
          deleteFunction={handleDeleteNist}
        />
        <Button variant="danger" onClick={() => setShowConfirmationModal(true)}>
          Delete
        </Button>
      </>
    )
  );
};

export default NistDeleteButton;
