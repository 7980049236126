import { Card, Col, Row } from "react-bootstrap";

import Dates from "./EstAndActDates.jsx";
import SubmissionsButton from "./SubmissionButton.jsx";

export default function ProgressPoints({
  progressPoints,
  getEditButton,
  showButtons,
  product,
}) {
  const progressPointTitles = {
    1: "Check-In Package",
    2: "Check-In Meeting",
    3: "ST Evaluation",
    4: "Test Ready",
    5: "Testing Complete",
    6: "Evaluator Checkout",
    7: "Validator Checkout",
    8: "PCL Posting",
  };

  const isPp6Or7 = (pp) => [6, 7].includes(pp.progress_point);
  const isResubmitted = (pp) => pp?.resubmission_counter !== null;
  const getCharCodeOfResubmission = (pp) =>
    String.fromCharCode(97 + pp.resubmission_counter);

  const getPointNumber = (pp) =>
    isPp6Or7(pp) && isResubmitted(pp)
      ? `${pp.progress_point}${getCharCodeOfResubmission(pp)}`
      : pp?.progress_point;

  const getEstimatedCompletionDate = (progressPoint) => {
    const dateMappings = {
      1: progressPoints[0]?.est_completion_dates[0]?.estimated_date,
      2: progressPoints[1]?.est_completion_dates[0]?.estimated_date,
      3: product?.st_evaluation_date,
      4: product?.test_ready_date,
      5: product?.testing_complete_date,
      6: product?.evaluator_checkout_date,
      7: product?.validator_checkout_date,
      8: product?.pcl_posting_date,
    };
    return dateMappings[progressPoint];
  };

  const getCardBorderStyle = (pp) => {
    return (
      {
        Completed: "success",
        "In Progress": "warning",
        "Ready for Release": "warning",
        Rejected: "danger",
        "Amended Package Needed": "danger",
        Resubmitted: "info",
        "Awaiting Changes": "info",
        "Awaiting Checkout": "3px solid #aa66cc",
      }[pp.status] || "dark"
    );
  };

  const getCardStatusName = (pp) => {
    return (
      {
        "Est. Completion Date Set": "Not Started",
        "In Progress": "Initialized",
        "Awaiting Checkout": "In Review",
        "Amended Package Needed": "Changes Needed",
      }[pp.status] || pp.status
    );
  };

  // ensuring `Awaiting Checkout` gets the correct purple border color
  const inReviewStyle = {
    height: "122px",
    width: "122px",
    margin: "auto",
    border: "3px solid #aa66cc",
  };

  const style = {
    height: "122px",
    width: "122px",
    borderWidth: 3,
    margin: "auto",
  };

  return (
    <Col className="mt-3">
      <Row>
        {progressPoints?.map((pp) => (
          <Col key={`progress-point-${pp.prog_id}`} className="p-0">
            <Card
              border={getCardBorderStyle(pp)}
              bg="light"
              className="rounded-circle justify-content-center"
              style={pp.status === "Awaiting Checkout" ? inReviewStyle : style}
            >
              <Card.Body className="text-center active-bar">
                <Card.Title className="progress-point-number small-font">
                  {getPointNumber(pp)}
                </Card.Title>
                <Card.Subtitle className="progress-point-heading fw-bold my-0 small-font">
                  {progressPointTitles[pp.progress_point]}
                </Card.Subtitle>
                {getEditButton(pp)}
                <Card.Text className="small-font">
                  {getCardStatusName(pp)}
                </Card.Text>
              </Card.Body>
            </Card>
            {isPp6Or7(pp) && pp?.est_completion_dates?.length > 0 ? (
              <SubmissionsButton pp={pp} product={product} />
            ) : (
              <Dates
                pp={pp}
                getEstimatedCompletionDate={getEstimatedCompletionDate}
              />
            )}
            {pp.progress_point !== 1 && (
              <Row
                className="mt-2 text-center justify-content-center"
                xs="auto"
              >
                {showButtons(pp)}
              </Row>
            )}
          </Col>
        ))}
      </Row>
    </Col>
  );
}
