import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Modal, Button, Row, Col, Alert } from "react-bootstrap";

import ReactSelect from "components/ReactSelect";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

import FileSection from "./FileSection";

const NISTForm = ({ show, onHide, refetch }) => {
  const { csrfToken, authToken } = useAuth();
  const [validated, setValidated] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [nistFiles, setNistFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [formAlert, setFormAlert] = useState({ message: "", type: "" });
  const [errors, setErrors] = useState({});
  const [productValid, setProductValid] = useState(true);
  const [nistEditedFileLabel, setNistEditedFileLabel] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_in_eval_products_without_nist/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        );
        setProducts(
          response.data.map((item) => ({
            value: item.product_id,
            label: "VID" + item.v_id,
          })),
        );
      } catch (error) {
        console.log(error);
        setFormAlert({ message: "Failed to load products.", type: "danger" });
      }
    };

    fetchProducts();
  }, [authToken]);

  const validateForm = () => {
    const newErrors = {};
    if (!formInfo.pid) {
      newErrors.pid = "Associated product is required.";
      setProductValid(false);
    }
    if (formInfo.reqd !== false && nistFiles.length === 0) {
      newErrors.nistFiles = "At least one NIST file is required.";
    }
    if (formInfo.reqd === false && !formInfo.just) {
      newErrors.just = "Justification is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "nist_file") {
      if (files.length) {
        const newFile = {
          file_type: "nist",
          file_source_type: "Certificate Review",
          file_display_name: formInfo.nist_file_display_name,
          uploaded_on: getISOCurrentDate(),
          file_name: files[0].name,
          file_label: formInfo.nist_file_label,
          active: true,
          file: files[0],
          private: formInfo.nist_file_public_status === "Public" ? false : true,
        };
        setNistFiles((prev) => [...prev, newFile]);
      }
    } else if (e.target.name === "nist_file_display_name") {
      setNistEditedFileLabel(e.target.value);
      setFormInfo({
        ...formInfo,
        [e.target.name]: e.target.value,
        nist_file_label: e.target.value,
      });
    } else if (e.target.name === "nist_file_label") {
      setNistEditedFileLabel(e.target.value);
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    } else {
      setFormInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!validateForm()) return;
    setProductValid(true);

    const nistData = new FormData();
    nistData.append("pid", formInfo.pid);

    // forInfo.reqd can be undefined if the user doesn't select radio button due to default checked, so I explicitly check for false
    if (formInfo.reqd === false) {
      nistData.append("stid", "No Certificate Review Needed");
      nistData.append("just", formInfo.just);
      nistData.append("reqd", formInfo.reqd);
    } else {
      nistData.append("reqd", true);
    }

    nistFiles.forEach((file) => {
      nistData.append("attachments_file", file.file);
      nistData.append("attachments_source_type", file.file_source_type);
      nistData.append("attachments_type", file.file_display_name);
      nistData.append("attachments_uploaded", file.uploaded_on);
      nistData.append("private", file.private);
      nistData.append("file_label", file.file_label);
    });

    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/create_nist_or_update_existing/?pid=${formInfo.pid}`,
        nistData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then(() => {
        setFormAlert({
          message: "Certificate Review added successfully!",
          type: "success",
        });
        onHide();
        refetch();
      })
      .catch((error) => {
        console.log("Unable to submit create or update NIST: ", error);
        setFormAlert({
          message:
            "Error submitting NIST Certificate Review. Please try again.",
          type: "danger",
        });
      });
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Certificate Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <Form.Label>Associated Product*</Form.Label>
              <ReactSelect
                id="nist-associated-product-select"
                options={products}
                isValid={productValid}
                name="pid"
                newObj={formInfo}
                handleChange={handleChange}
              />
              {errors.pid && (
                <Form.Text className="text-danger">{errors.pid}</Form.Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                name="reqd"
                label="Required, Please Select Files Below"
                onChange={() => setFormInfo({ ...formInfo, reqd: true })}
                defaultChecked
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                name="reqd"
                label="No NIST Review Needed, Please Provide Justification"
                onChange={() => setFormInfo({ ...formInfo, reqd: false })}
              />
            </Col>
          </Row>
          {formInfo.reqd === false ? (
            <>
              <Form.Group controlId="justification">
                <Form.Label>Justification*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="just"
                  value={formInfo.just || ""}
                  onChange={handleChange}
                  isInvalid={!!errors.just}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.just}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : (
            <>
              <FileSection
                formInfo={formInfo}
                setNistFiles={setNistFiles}
                nistFiles={nistFiles}
                handleChange={handleChange}
                nistEditedFileLabel={nistEditedFileLabel}
              />
              {errors.nistFiles && (
                <Alert variant="danger">{errors.nistFiles}</Alert>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {formAlert.message && (
            <Alert variant={formAlert.type}>{formAlert.message}</Alert>
          )}
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NISTForm;
