import axios from "axios";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

import "../ProgressPointModal.css";
import HTMLEditor from "components/UI/HTMLEditor";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

const PP345DATA = {
  3: {
    title: "ST EVALUATION - PROGRESS POINT 3",
    statusText: "Status of ST Evaluation:",
    fileTypes: ["Security Target", "Other Documents"],
    fileSourceType: "Progress Point 3",
    completionDate: "st_evaluation_date",
  },
  4: {
    title: "TEST READY - PROGRESS POINT 4",
    statusText: "Status of Test Ready:",
    fileTypes: ["Test Reports", "Other Documents"],
    fileSourceType: "Progress Point 4",
    completionDate: "test_ready_date",
  },
  5: {
    title: "TESTING COMPLETE - PROGRESS POINT 5",
    statusText: "Status of Testing Complete:",
    fileTypes: ["Test Reports", "Other Documents"],
    fileSourceType: "Progress Point 5",
    completionDate: "testing_complete_date",
  },
};

export default function ProgressPointModal345({
  show,
  handleClose,
  product,
  progressPoint,
  readOnly,
  allFiles,
  isEdit,
  refetch,
  setRefetchNotes,
}) {
  const { authToken, csrfToken, currentUser } = useAuth();
  const [formInfo, setFormInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const loadFileByPPFileSource = useCallback(() => {
    let file_source_type =
      PP345DATA[progressPoint.progress_point]?.fileSourceType;
    axios
      .get(
        `${
          process.env.REACT_APP_DJANGO_ENDPOINT
        }file/get_files_by_type_and_file_source_type_and_type_id/?file_type=product&file_source_type=${file_source_type}&file_type_id=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setFiles(response.data);
      })
      .catch(() => {
        setFiles([]);
      });
  }, [allFiles, authToken, isEdit, product.product_id, readOnly]);

  const pullOriginalFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=product&file_source_type=Check In&file_type_id=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setOriginalFiles(response.data);
      })
      .catch(() => {
        console.log("Error getting original files");
      });
  }, [authToken, product.product_id]);

  useEffect(() => {
    if (progressPoint) {
      pullOriginalFiles();
      setFiles([]);
      loadFileByPPFileSource();
    }
  }, [progressPoint, pullOriginalFiles, show]);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      const newFiles = files ? files : [];
      newFiles.push({
        file_type: "product",
        file_source_type:
          PP345DATA[progressPoint.progress_point]?.fileSourceType,
        file_display_name: formInfo.file_display_name,
        uploaded_on: getISOCurrentDate(),
        file_name: e.target.files[0].name,
        file_label: formInfo.file_label,
        active: true,
        file: e.target.files[0],
        private: formInfo.file_public_status === "Public" ? false : true,
      });
      setFiles([...newFiles]);
    } else {
      if (e.target.name === "file_display_name") {
        setEditedFileLabel(e.target.value);
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          file_label: e.target.value,
        });
      } else if (e.target.name === "file_label") {
        setEditedFileLabel(e.target.value);
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      } else {
        setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
      }
    }
  };

  const removeFile = async (fileInfo, idx) => {
    if (fileInfo.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${fileInfo.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          loadFileByPPFileSource();
        })
        .catch((error) => console.log("Unable to update file", error));
    } else {
      const newFiles = files.slice();
      newFiles.splice(idx, 1);
      setFiles(newFiles);
    }
  };

  const handleAddNote = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: formInfo?.notes,
          note_type: "product",
          note_type_id: product?.product_id,
          submitted_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          submitted_by: currentUser.id,
          progress_point: progressPoint.progress_point,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setRefetchNotes(response.data.note_id))
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const updateProduct = async () => {
    const submittedData = new FormData();
    const completionDateKey =
      PP345DATA[progressPoint.progress_point].completionDate;
    const completionDateValue = formInfo.completion_date
      ? new Date(formInfo.completion_date).toJSON()
      : new Date(product[completionDateKey]).toJSON();
    submittedData.append(completionDateKey, completionDateValue);

    if (files.length > 0) {
      for (let f in files) {
        if (!files[f].file_mime_type) {
          submittedData.append("attachments_file", files[f].file);
          submittedData.append(
            "attachments_source_type",
            files[f].file_source_type,
          );
          submittedData.append("attachments_type", files[f].file_display_name);
          submittedData.append("attachments_uploaded", files[f].uploaded_on);

          submittedData.append("private", files[f].private);
          submittedData.append("file_label", files[f].file_label);
        }
      }
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then(() => {
        if (formInfo.notes) {
          handleAddNote();
        }
        setFiles([]);
      })
      .catch((error) => console.log("Unable to submit new product: ", error));
  };

  const updateProgressPoint = async (e, combinedSave) => {
    let data = {
      product: product.product_id,
      progress_point: progressPoint.progress_point,
    };

    if (combinedSave) {
      data["status"] = "Completed";
      data["new_submission_date"] = true;
    } else if (!isEdit) {
      data["status"] = "In Progress";
    }

    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        console.log("progress updated");
      })
      .catch((error) => console.log(error));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // not a combined save
    await updateProgressPoint(e, false);
    await updateProduct();
    refetch();
    handleClose();
  };

  //false, true
  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateProgressPoint(e, true);
    refetch();
    handleClose();
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose();
        }}
        enforceFocus={enforceFocusModal}
      >
        <Form onSubmit={handleSubmit} id="progress-point-form">
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
              <h3 className="text-bright-navy">
                {PP345DATA[progressPoint.progress_point]?.title}
                {isEdit ? (
                  " EDIT"
                ) : ["Not Started", "Est. Completion Date Set"]?.includes(
                    progressPoint?.status,
                  ) ? (
                  " INITIATE"
                ) : progressPoint?.status === "In Progress" ? (
                  " COMPLETE"
                ) : (
                  <></>
                )}{" "}
                for VID: {product.v_id}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <Row className="mt-3">
                    <Col sm={5} mb={2}>
                      <Form.Group controlId="completion_date">
                        <Form.Label className="fw-bold">
                          Current Estimated Date for PP
                          {progressPoint?.progress_point}
                        </Form.Label>
                        <Form.Control
                          className="mb-3"
                          type="date"
                          name="completion_date"
                          onChange={handleChange}
                          plaintext={readOnly}
                          readOnly={readOnly}
                          defaultValue={moment
                            .utc(
                              product[
                                PP345DATA[progressPoint.progress_point]
                                  ?.completionDate
                              ],
                            )
                            .format("YYYY-MM-DD")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {files.length > 0 && (
                    <Row>
                      {readOnly && (
                        <p className="fw-bold form-title mt-3 mb-1">
                          ATTACHED FILES
                        </p>
                      )}
                      {!readOnly && (
                        <p className="fw-bold form-title mt-3 mb-1">
                          ATTACH FILES
                        </p>
                      )}
                    </Row>
                  )}

                  <Row>
                    {!readOnly && (
                      <>
                        <h6>Progress Point Files</h6>
                        <Col xl={3} sm={12}>
                          <Form.Group
                            className="mb-4 text-start"
                            controlId="file_display_name"
                          >
                            <Form.Label className="small text-secondary">
                              Type
                            </Form.Label>
                            <Form.Select
                              name="file_display_name"
                              onChange={handleChange}
                            >
                              <option value=""></option>
                              {PP345DATA[
                                progressPoint.progress_point
                              ]?.fileTypes.map((type, idx) => (
                                <option value={type} key={idx}>
                                  {type}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl={3} sm={12}>
                          <Form.Group
                            className="mb-4 text-start"
                            controlId="file_public_status"
                          >
                            <Form.Label className="small text-secondary">
                              Document Visibility
                            </Form.Label>
                            <Form.Select
                              name="file_public_status"
                              onChange={handleChange}
                            >
                              <option value=""></option>
                              <option value="Public">Public</option>
                              <option value="Proprietary">Proprietary</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl={6} sm={12}>
                          <Form.Group controlId="file_label">
                            <Form.Label className="small text-secondary">
                              File Label
                            </Form.Label>
                            <Form.Control
                              value={editedFileLabel}
                              type="text"
                              name="file_label"
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={6} sm={12} className="mb-3">
                          <Form.Group controlId="file">
                            <Form.Label className="small text-secondary">
                              Attach Files
                            </Form.Label>
                            <Form.Control
                              type="file"
                              name="file"
                              onChange={handleChange}
                              disabled={
                                !formInfo.file_public_status ||
                                !formInfo.file_display_name
                              }
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col lg={10}>
                      {files?.length > 0 && (
                        <div className="border">
                          <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                          {files
                            .filter((file) => file?.active === true)
                            ?.map((file, idx) => (
                              <Row
                                className="m-3 d-flex justify-content-around"
                                key={idx}
                              >
                                <Col sm={4}>{file.file_display_name}</Col>
                                <Col sm={4}>{file.file_label}</Col>
                                <Col sm={2}>
                                  {file.private ? "Proprietary" : "Public"}
                                </Col>
                                {!readOnly && (
                                  <Col
                                    sm={1}
                                    className="d-flex justify-content-center"
                                  >
                                    <Button
                                      variant="outline-primary"
                                      className="attachment-remove"
                                      onClick={() => removeFile(file, idx)}
                                    >
                                      X
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            ))}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10}>
                      {originalFiles?.length > 0 && (
                        <div className="mt-5">
                          <p className="fw-bold form-title mb-1">
                            ORIGINAL ATTACHED FILES:
                          </p>
                          <div className="border">
                            <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                            {originalFiles?.map((file, idx) => (
                              <Row
                                className="m-3 d-flex justify-content-around"
                                key={idx}
                              >
                                <Col sm={4}>{file.file_display_name}</Col>
                                <Col sm={4}>{file.file_label}</Col>
                                <Col sm={2}>
                                  {file.private ? "Proprietary" : "Public"}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {!readOnly && (
                    <Row className="mt-5">
                      <Col>
                        <p className="fw-bold form-title mb-2">NOTES*</p>
                        <HTMLEditor
                          name="notes"
                          handleChange={handleChange}
                          setIsValid={() => {}}
                          isValid={true}
                          setEnforceFocusModal={setEnforceFocusModal}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row className="d-flex justify-content-end">
                <Col sm={12} md={3} lg={2}>
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                {!readOnly && (
                  <Col sm={12} md={3} lg={2}>
                    <Button
                      variant="outline-primary"
                      onClick={handleSave}
                      className="square-button"
                    >
                      Save
                    </Button>
                  </Col>
                )}
                {!isEdit && (
                  <Col sm={12} md={5} lg={2} className="me-3">
                    <Button
                      variant="primary"
                      type="submit"
                      className="square-button px-2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Save & Submit
                    </Button>
                  </Col>
                )}
              </Row>
            </Container>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
