import { Link } from "react-router-dom";

const VidCell = ({ project }) => {
  const { product_id, v_id } = project;
  if (!product_id || !v_id) return null;

  return (
    <Link to={`details/${product_id}`} className="text-decoration-underline">
      {v_id}
    </Link>
  );
};
export default VidCell;
