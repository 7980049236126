import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import {
  Container,
  Row,
  OverlayTrigger,
  Button,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import TQActiveVoting from "components/TRRTS/TRRTSManager/TQVoting/TQActiveVoting";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

const TQTable = ({ tableRef, setTrrtCount }) => {
  const { csrfToken, authToken, permissions } = useAuth();

  const updateTechnicalHistory = async (data) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/history/`,
        { ...data },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        console.log("technical query history updated");
      })
      .catch((err) => {
        console.log("unable to update technical query history");
      });
  };

  const trrtColumns = [
    {
      title: "ID",
      field: "tq_id",
      render: (rowData) => (
        <Button
          variant="link"
          href={`/community/technicalqueries/details/${rowData?.tq_id}`}
          className="text-decoration-underline small-font"
        >
          {rowData?.tq_id}
        </Button>
      ),
    },
    {
      title: "VID",
      field: "tq_id",
      render: (rowData) => (
        <ListGroup>
          {rowData?.product_evaluation?.map((product, idx) => (
            <OverlayTrigger
              key={idx}
              placement="top"
              overlay={<Tooltip>{product?.product_name}</Tooltip>}
            >
              <Link
                to={`/community/products/details/${product?.product_id}`}
                className="text-decoration-underline"
              >
                <ListGroup.Item className="no-hover-effect">
                  VID{product?.v_id}
                </ListGroup.Item>
              </Link>
            </OverlayTrigger>
          ))}
        </ListGroup>
      ),
    },
    {
      title: "Submitted On",
      render: (rowData) =>
        rowData.submitted_on
          ? moment.utc(rowData.submitted_on).format("MM/DD/YYYY")
          : "",
    },
    {
      title: "Team",
      field: "team.team",
    },
    { title: "Question", field: "title" },
    { title: "Status", field: "status" },
    {
      title: "Vote",
      hidden: !permissions?.role_permissions?.find(
        (permission) => permission.area === "Technical Questions",
      )?.update,
      render: (rowData) => (
        <Container>
          {rowData?.status === "Resolution Proposed" ? (
            <Row>
              <TQActiveVoting
                technicalQuery={rowData}
                updateTechnicalHistory={updateTechnicalHistory}
                dashboardTable
              />
            </Row>
          ) : (
            <p>Resolution Not Proposed</p>
          )}
        </Container>
      ),
    },
    {
      title: "Actions",
      headerStyle: { display: "none" },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <Link
          to={`technicalqueries/details/${rowData.tq_id}`}
          relative="route"
          className="text-decoration-none"
        >
          <Button variant="link" className="m-1 p-0 btn-link">
            <VisibilityIcon color="action" /> View
            <span className="visually-hidden">Action</span>
          </Button>
        </Link>
      ),
    },
  ];

  const options = {
    sorting: true,
    columnsButton: true,
  };

  const fetchTQUrl = () => {
    if (permissions?.role_type === "NIAP") {
      return "technical-query/niap_tq_table_data/";
    } else if (permissions?.role_type === "Lab") {
      return "technical-query/lab_tq_table_data/";
    } else if (permissions?.role_type === "Validator") {
      return "technical-query/niap_tq_table_data/";
    } else if (permissions?.role_type === "SME") {
      return "technical-query/niap_tq_table_data/";
    }
  };

  return (
    <ResponsiveMaterialTable
      id={"trrt-table"}
      title="TRRTs"
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve, reject) => {
          // Extract the necessary information from the query object
          const { page, pageSize, search, filters, orderBy, orderDirection } =
            query;

          let newFilters = filters.map((filter) => {
            let value = "";
            if (Array.isArray(filter.value)) {
              value = filter.value;
            } else {
              value = filter.value.replace(/['"]+/g, "");
            }
            return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
          });
          const params = {
            offset: page * pageSize,
            limit: pageSize,
            search: search,
            filters: newFilters,
            orderBy: orderBy?.field,
            orderDirection: orderDirection,
          };

          params["status"] =
            "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted";
          params["active"] = JSON.stringify(true);

          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchTQUrl()}?${qs.stringify(params, { arrayFormat: "comma" })}`,
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              if (setTrrtCount) {
                setTrrtCount(response.data.count);
              }
              resolve({
                data: response.data.results,
                page: page,
                totalCount: response.data.count,
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
      }
      options={options}
      columns={trrtColumns}
    />
  );
};

export default TQTable;
