import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";

import {
  useAddTqVoteMutation,
  useDeleteTqVoteMutation,
  useTqVotesByIdQuery,
  useUpdateTqVoteMutation,
} from "api/memberPortal/voting/tqVotes/tqVotes.api";
import { useAuth } from "hooks/useAuth";
import { getCurrentDate } from "utils/timeUtils";

import VotingOverlay from "../VotingOverlay";

import { Award } from "./Award";

const TQActiveVoting = ({
  technicalQuery,
  updateTechnicalHistory,
  dashboardTable,
}) => {
  const { currentUser, permissions } = useAuth();

  const [triggerAddTqVote, deleteTqVoteResult] = useAddTqVoteMutation();
  const [triggerDeleteTqVote, updateTqVoteResult] = useDeleteTqVoteMutation();
  const [triggerUpdateTqVote, addTqVoteResult] = useUpdateTqVoteMutation();

  const getVotesResult = useTqVotesByIdQuery(technicalQuery.tq_id, {
    skip: !technicalQuery.tq_id,
  });
  const { data: votes = [] } = getVotesResult;
  const isLoading =
    getVotesResult.isFetching ||
    deleteTqVoteResult.isLoading ||
    updateTqVoteResult.isLoading ||
    addTqVoteResult.isLoading;

  const currentUserVote = votes.find((v) => v.voter.id === currentUser?.id);

  const handleVote = async (vote) => {
    if (isLoading) return;

    if (currentUserVote) {
      if (vote === currentUserVote.approved) {
        await triggerDeleteTqVote(currentUserVote.id);
      } else {
        await triggerUpdateTqVote({
          userId: currentUserVote.id,
          body: {
            approved: vote,
            vote_date: getCurrentDate(),
          },
        });
      }
    } else {
      await triggerAddTqVote({
        tq_id: technicalQuery.tq_id,
        voter: currentUser.id,
        approved: vote,
        vote_date: getCurrentDate(),
      });
    }
    const TechnicalHistoryUpdate = {
      reason_for_update: "Updated Draft Response Vote",
      ...(dashboardTable && { tq_id: technicalQuery.tq_id }),
    };

    updateTechnicalHistory(TechnicalHistoryUpdate);
  };

  const getVoteCount = (approved = true) => {
    if (!votes) return 0;
    return votes.filter((v) => v.approved === approved).length;
  };

  const approvedVoteCount = getVoteCount(true);
  const rejectedVoteCount = getVoteCount(false);

  return (
    <div>
      <Col
        xs={1}
        className="d-flex align-items-center w-auto p-0 justify-content-end"
      >
        {!dashboardTable && <p className="m-0 mx-1">Please Vote Here!</p>}
        {/* negative vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            rejectedVoteCount > 0 ? (
              <Popover
                style={{
                  maxWidth: "500px",
                  width: "fit-content",
                }}
              >
                <Popover.Body>
                  {votes
                    .filter((vote) => vote.approved === false)
                    .map((vote, idx, arr) => (
                      <div key={vote.voter.id}>
                        <VotingOverlay
                          voter={vote.voter}
                          date={vote.vote_date}
                          divider={idx !== arr.length - 1}
                        />
                      </div>
                    ))}
                </Popover.Body>
              </Popover>
            ) : (
              <span></span>
            )
          }
        >
          <div
            className={`mx-2 d-flex ${dashboardTable ? "flex-column" : ""} align-items-center`}
          >
            {currentUserVote?.approved === false ? (
              <ThumbDownIcon
                data-testid="thumb-down-icon"
                style={{
                  color: "var(--bs-danger)",
                }}
                className="clickable"
                onClick={() => handleVote(false)}
              />
            ) : (
              <ThumbDownOffAltIcon
                data-testid="thumb-down-off-icon"
                className="clickable"
                onClick={() => handleVote(false)}
              />
            )}
            <p className={`${dashboardTable ? "mt-1" : "mx-2"} mb-0`}>
              {rejectedVoteCount}
            </p>
          </div>
        </OverlayTrigger>

        {/* positive vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            approvedVoteCount > 0 ? (
              <Popover
                style={{
                  maxWidth: "500px",
                  width: "fit-content",
                }}
              >
                <Popover.Body>
                  {votes
                    .filter((vote) => vote.approved === true)
                    .map((vote, idx, arr) => (
                      <div key={vote.voter.id}>
                        <VotingOverlay
                          voter={vote.voter}
                          date={vote.vote_date}
                          divider={idx !== arr.length - 1}
                        />
                      </div>
                    ))}
                </Popover.Body>
              </Popover>
            ) : (
              <span></span>
            )
          }
        >
          <div
            className={`mx-2 d-flex ${dashboardTable ? "flex-column" : ""} align-items-center`}
          >
            {currentUserVote?.approved ? (
              <ThumbUpIcon
                data-testid="thumb-up-icon"
                style={{
                  color: "var(--bs-success)",
                }}
                className="clickable"
                onClick={() => handleVote(true)}
              />
            ) : (
              <ThumbUpOffAltIcon
                data-testid="thumb-up-off-icon"
                className="clickable"
                onClick={() => handleVote(true)}
              />
            )}
            <p className={`${dashboardTable ? "mt-1" : "mx-2"} mb-0`}>
              {approvedVoteCount}
            </p>
          </div>
        </OverlayTrigger>

        {/* Award Icon */}
        <div className="mx-2 d-flex flex-column align-items-center">
          <Award votes={votes} />
          {dashboardTable && <p />}
        </div>
      </Col>
      {!["TD Required", "Resolved", "Proposed", "Accepted"].includes(
        technicalQuery?.status,
      ) &&
        permissions.role_type === "NIAP" &&
        !dashboardTable && (
          <Row>
            <Col className="d-flex justify-content-end">
              <p
                className={`text-muted m-0 mt-1`}
                style={{ textWrapMode: "nowrap" }}
              >
                A total of{" "}
                {permissions.role_type === "NIAP" ? "1 vote" : "3 votes"} needed
                to save and send to customer as {permissions.role_type}.
              </p>
            </Col>
          </Row>
        )}
    </div>
  );
};

export default TQActiveVoting;
