import moment from "moment";

const ArchivedDateCell = ({ project }) => {
  if (!project.sunset_date || project.status !== "Archived") {
    return "Pending";
  }
  return moment.utc(project.sunset_date).format("MM/DD/YYYY");
};

export default ArchivedDateCell;
