import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import {
  useUpdateNoteMutation,
  useAddNoteMutation,
} from "api/memberPortal/notes/notes.api";
import { useAuth } from "hooks/useAuth";

import { getCurrentDate } from "../../../../utils/timeUtils";
import HTMLEditor from "../../../UI/HTMLEditor";

const NistNotesForm = ({
  show,
  currentNote,
  item,
  handleCloseNotesForm,
  id,
  replyTo,
}) => {
  const { currentUser, permissions } = useAuth();
  const [note, setNote] = useState({});
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const [triggerUpdateNote] = useUpdateNoteMutation();
  const [triggerAddNote] = useAddNoteMutation();

  const handleChange = (e) =>
    setNote({ ...note, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!note.note) {
      e.stopPropagation();
    } else {
      if (currentNote) {
        try {
          await triggerUpdateNote({
            noteId: currentNote.note_id,
            body: {
              visible_to_labs: note.visible_to_labs,
              note: note.note,
              title: note.title,
            },
          }).unwrap();
          handleCloseNotesForm();
        } catch (error) {
          console.log("Unable to update note: " + error);
        }
      } else {
        try {
          await triggerAddNote({
            visible_to_labs: note.visible_to_labs,
            note: note.note,
            title: note?.title,
            submitted_by: currentUser.id,
            submitted_on: getCurrentDate(),
            note_type: "nist",
            note_type_id: id,
            in_response_to: replyTo,
          }).unwrap();
          handleCloseNotesForm();
        } catch (error) {
          console.log("Unable to add note: " + error);
        }
      }
    }
  };

  useEffect(() => {
    if (!currentNote) {
      if (permissions?.role_type === "Lab") {
        setNote({ visible_to_labs: true });
      } else {
        setNote({ visible_to_labs: false }); //Nist Notes should not be visible to labs by default
      }
    } else {
      setNote({ ...currentNote });
    }
  }, [show, currentNote, permissions?.role_type]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleCloseNotesForm}
      enforceFocus={enforceFocusModal}
    >
      <Modal.Header closeButton>
        <h2>
          + NOTE for Cert Review on Product {item?.pid?.product_id} + VID{" "}
          {item?.pid?.v_id}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Label className="fw-bold">
            Submitter: {currentUser.username}
          </Form.Label>
          {(permissions.role_type === "NIAP" ||
            permissions.role_type === "Validator") && (
            <Form.Group controlId="note">
              <Form.Label>Make Visible to Labs?</Form.Label>
              <Form.Check
                className="mx-3"
                type="radio"
                name="visible_to_labs"
                value={true}
                inline
                label="Yes"
                onChange={handleChange}
                checked={
                  note.visible_to_labs === true ||
                  note.visible_to_labs === "true"
                }
                id="1"
              />
              <Form.Check
                type="radio"
                name="visible_to_labs"
                value={false}
                inline
                label="No"
                onChange={handleChange}
                checked={
                  note.visible_to_labs === false ||
                  note.visible_to_labs === "false"
                }
                id="2"
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="title">
            <Form.Label className="fw-bold">{`Title`}</Form.Label>
            <Form.Control
              type="text"
              name="title"
              onChange={handleChange}
              defaultValue={currentNote?.title}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="note">
            <Form.Label className="fw-bold">Overview:*</Form.Label>
            <HTMLEditor
              name="note"
              handleChange={handleChange}
              setIsValid={() => {}}
              isValid={note.note}
              defaultValue={currentNote?.note}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          type="reset"
          onClick={() => handleCloseNotesForm()}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          {currentNote?.note_id ? "Update" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NistNotesForm;
