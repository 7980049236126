import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { addRole } from "app/role";
import { addUser } from "app/user";
import { useAuth } from "hooks/useAuth";

const LoginSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { authToken, tempToken, updateCookies } = useAuth();

  useEffect(() => {
    if (!authToken && !tempToken) {
      updateCookies();
      return;
    }

    const redirect = async () => {
      const userData = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      );
      dispatch(addUser(userData.data));
      const roleData = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/getRole/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      );
      dispatch(addRole(roleData.data));

      if (roleData.data.role_type === "External") navigate("/");
      else navigate("/community/");
    };
    if (state?.is_login) {
      redirect();
    } else {
      setTimeout(() => {
        navigate("/login/");
      }, 1000);
    }
  }, [authToken, dispatch, navigate, state, tempToken, updateCookies]);

  return (
    <Card className="login-card success">
      <Card.Body>
        <CheckBoxIcon color="primary" sx={{ fontSize: 100 }} />
        <Card.Title as="h4">Success</Card.Title>
        <p className="small">You are being redirected...</p>
      </Card.Body>
    </Card>
  );
};

export default LoginSuccess;
