import { baseApi } from "api/BaseApi";

import { Note } from "./notes.types";

const NOTE_BASE_QUERY = "note";

const noteApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNotes: build.query<Note, string>({
      query: (nistId) => `${NOTE_BASE_QUERY}/${nistId}/`,
      providesTags: ["NOTE"],
    }),
    getNistNotes: build.query<Note[], string>({
      query: (nistId) =>
        `${NOTE_BASE_QUERY}/get_nist_notes_by_nist_id/?nist_id=${nistId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ note_id }) => ({
                type: "NOTE" as const,
                note_id,
              })),
              "NOTE",
            ]
          : ["NOTE"],
      transformResponse: (response: Note[]) => {
        const unfilteredNotes = response;
        const filteredNotes: Note[] = [];
        const responses: { [key: string]: Note[] } = {};
        unfilteredNotes.forEach((note) => {
          if (!note.in_response_to) {
            filteredNotes.push(note);
          } else {
            if (!responses[note.in_response_to.note_id]) {
              responses[note.in_response_to.note_id] = [];
            }
            responses[note.in_response_to.note_id].push(note);
          }
        });
        for (let key in responses) {
          const children = responses[key];
          children.forEach((note, idx) => {
            if (responses[note.note_id]) {
              note["responses"] = responses[note.note_id];
              responses[key][idx] = note;
            }
          });
        }
        filteredNotes.forEach((note) => {
          if (responses[note.note_id]) {
            note["responses"] = responses[note.note_id];
          }
        });
        return filteredNotes;
      },
    }),
    deleteNote: build.mutation<void, number>({
      query: (noteId) => ({
        url: `${NOTE_BASE_QUERY}/${noteId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["NOTE"],
    }),
    updateNote: build.mutation<Note, { noteId: number; body: Partial<Note> }>({
      query: ({ noteId, body }) => ({
        url: `${NOTE_BASE_QUERY}/${noteId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["NOTE"],
    }),
    addNote: build.mutation<Note, Note>({
      query: (body) => ({
        url: `${NOTE_BASE_QUERY}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["NOTE"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotesQuery,
  useGetNistNotesQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useAddNoteMutation,
} = noteApi;
