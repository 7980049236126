import moment from "moment";
import { Row, Col } from "react-bootstrap";

const SubmissionDates = ({ pp, product }) => {
  const estimated_dates = [...pp.est_completion_dates];
  const submission_dates = [...pp.submission_dates];

  const getCurrEstCompletionDate = () => {
    if (pp?.progress_point === 6) {
      if (
        pp.est_completion_dates.length > 0 &&
        ["Amended Package Needed", "Rejected"].includes(pp.status)
      ) {
        return (
          <Row>
            <Col className="p-0">
              <p className="mt-1 mb-0 fw-bold">
                {`${pp?.progress_point}${String.fromCharCode(65 + pp.resubmission_counter).toLowerCase()}. Resubmission`}
              </p>
              <p className="small-font m-0 p-0 text-secondary">
                {product?.evaluator_checkout_date
                  ? moment
                      .utc(product.evaluator_checkout_date)
                      .format("YYYY.MM.DD")
                  : "No Date Set"}{" "}
                (est. completion)
                <br />
              </p>
            </Col>
          </Row>
        );
      }
    } else {
      if (["In Progress"].includes(pp.status)) {
        return (
          <Row>
            <Col className="p-0">
              <p className="mt-1 mb-0 fw-bold">
                {`${pp?.progress_point}${String.fromCharCode(65 + pp.resubmission_counter).toLowerCase()}. Resubmission`}
              </p>
              <p className="small-font m-0 p-0 text-secondary">
                {product?.validator_checkout_date
                  ? moment
                      .utc(product.validator_checkout_date)
                      .format("YYYY.MM.DD")
                  : "No Date Set"}{" "}
                (est. completion)
                <br />
              </p>
            </Col>
          </Row>
        );
      }
    }
  };

  return (
    <>
      {estimated_dates.map((est_date, idx) => (
        <Row key={`${pp.progress_point}-date-${est_date.id}`}>
          <Col className="p-0">
            <p className="mt-1 mb-0 fw-bold">
              {idx === 0
                ? `${pp.progress_point}. Submission`
                : `${pp.progress_point}${String.fromCharCode(97 + idx - 1)}. Resubmission`}
            </p>
            <p className="small-font m-0 p-0 text-secondary">
              {est_date?.estimated_date
                ? moment.utc(est_date.estimated_date).format("YYYY.MM.DD")
                : "No Date Set"}{" "}
              (est. completion)
              <br />
              {submission_dates[idx]?.estimated_date
                ? moment
                    .utc(submission_dates[idx].estimated_date)
                    .format("YYYY.MM.DD")
                : "No Date Set"}{" "}
              (act. completion)
            </p>
          </Col>
        </Row>
      ))}
      {getCurrEstCompletionDate()}
    </>
  );
};

export default SubmissionDates;
