import parse from "html-react-parser";
import { useState } from "react";
import { Accordion, Button, Alert } from "react-bootstrap";

import { useUpdateNistMutation } from "api/memberPortal/certReview/certReview.api";
import { handleAlertAndMailer } from "components/Products/Helper/functions";
import { useAuth } from "hooks/useAuth";
import { getCurrentDate } from "utils/timeUtils";

const OfficialResponse = ({ canEditNist, certReview, handleItemClick }) => {
  const [alert, setAlert] = useState({});
  const { currentUser, csrfToken, authToken } = useAuth();

  const [triggerUpdateNist] = useUpdateNistMutation();

  const pendingFinalReview = async () => {
    try {
      const now = getCurrentDate();
      await triggerUpdateNist({
        nistId: certReview.crid,
        body: {
          status: "Pending Final Review",
          editorext: currentUser.id,
          modext: now,
          due: now,
        },
      }).unwrap();
      setAlert({
        message: "Successfully set Status to Pending Final Review!",
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      setAlert({
        message:
          "Error setting status to Pending Final Review. Please try again.",
        variant: "danger",
      });
    }
  };

  const sendUpdateEmail = () => {
    let recipients = { to: ["NCERT Team"] };
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: certReview.crid,
      alert_type: "Nist",
      subject: `Certificate Review Update Shared for ${certReview.pid.v_id}`,
      recipients: recipients,
      alert_text: `Certificate Review Update Shared for ${certReview.pid.v_id}`,
    });
  };

  const acceptAndRelease = async () => {
    try {
      const now = getCurrentDate();
      await triggerUpdateNist({
        nistId: certReview.crid,
        body: {
          status: "Approved",
          editorext: currentUser.id,
          modext: now,
          enddate: now,
        },
      });
      sendUpdateEmail();
      setAlert({
        message:
          "Successfully Released Official Response! Status set to Approved.",
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      setAlert({
        message: "Error Releasing Official Response. Please try again.",
        variant: "danger",
      });
    }
  };

  const rejectAndRelease = async () => {
    try {
      const now = getCurrentDate();
      await triggerUpdateNist({
        nistId: certReview.crid,
        body: {
          status: "Rejected - Revision Required",
          editorext: currentUser.id,
          modext: now,
          enddate: now,
        },
      });
      sendUpdateEmail();
      setAlert({
        message:
          "Successfully Released Official Response! Status set to Rejected - Revision Required.",
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      setAlert({
        message: "Error Releasing Official Response. Please try again.",
        variant: "danger",
      });
    }
  };

  return (
    <Accordion.Item eventKey="response" className="border-bottom-dark">
      <Accordion.Header onClick={() => handleItemClick("response")}>
        <h4>Official Response</h4>
      </Accordion.Header>
      <Accordion.Body>
        {alert?.message && (
          <Alert
            variant={alert?.variant}
            onClose={() => setAlert({})}
            dismissible
          >
            {alert?.message}
          </Alert>
        )}
        <div className="p-3 m-2">
          {canEditNist && certReview?.response && parse(certReview?.response)}
          {!canEditNist &&
            [
              "Approved",
              "Rejected - Revision Required",
              "Pending Final Review",
            ]?.includes(certReview?.stid?.status) &&
            certReview?.response &&
            parse(certReview?.response)}
          {canEditNist && certReview?.response && (
            <div className="text-end">
              <Button
                className="m-2 w-sm-100"
                onClick={pendingFinalReview}
                variant="primary"
              >
                Pending Final Review
              </Button>
              <Button
                className="m-2 w-sm-100"
                onClick={acceptAndRelease}
                variant="success"
              >
                Accept and Release
              </Button>
              <Button
                className="m-2 w-sm-100"
                onClick={rejectAndRelease}
                variant="danger"
              >
                Reject and Release
              </Button>
            </div>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default OfficialResponse;
