import moment from "moment";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import NistDeleteButton from "../NistDeleteButton";

import CertReqdEditableField from "./CertReqdEditableField";
import EnddateEditableField from "./EnddateEditableField";
import PPViewField from "./PPViewField";
import RcvdEditableField from "./RcvdEditableField";
import StatusEditableField from "./StatusEditableField";

const NISTHeader = ({ certReview, canEditNist }) => {
  const [alert, setAlert] = useState({ message: "", variant: "" });
  return (
    <>
      {alert.message && (
        <Row>
          <Col>
            <Alert
              variant={alert.variant}
              dismissible
              onClose={() => setAlert({ message: "", variant: "" })}
            >
              {alert.message}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="mt-4 mb-3">
          <h3 className="text-uppercase fw-bold">NIST Certificate Review</h3>
        </Col>
        <Col className="mt-4 mb-3 text-end">
          <NistDeleteButton certReview={certReview} setAlert={setAlert} />
        </Col>
      </Row>
      <Row className="mt-3 border-bottom border-dark">
        <Col sm={12} md={5} className="border-md-left">
          <h3>Details for Cert Review {certReview?.pid?.v_id}</h3>
        </Col>
        <Col sm={12} md={7}>
          <h3>
            <a
              href={`/community/products/details/${certReview?.pid?.product_id}`}
            >
              {certReview?.pid?.product_name}
            </a>
          </h3>
        </Col>
      </Row>
      <Row className="mt-2 border-bottom border-dark">
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">Status</p>
          <StatusEditableField
            value={certReview?.stid?.status}
            canEditNist={canEditNist}
            certReview={certReview}
          />
        </Col>
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">PP Claim</p>
          <PPViewField certReview={certReview} />
        </Col>
        <Col xs={12} md={3} className="border-md-left">
          <p className="fw-bold">Cert Review Required</p>
          <CertReqdEditableField
            value={certReview?.reqd ? "Yes" : "No"}
            canEditNist={canEditNist}
            certReview={certReview}
          />
        </Col>
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">Submission Date</p>
          <RcvdEditableField
            value={
              certReview?.rcvd
                ? moment.utc(certReview.rcvd).format("YYYY.MM.DD")
                : "------"
            }
            canEditNist={canEditNist}
            certReview={certReview}
          />
        </Col>
        <Col xs={12} md={3}>
          <p className="fw-bold">Completion or Termination Date </p>
          <EnddateEditableField
            value={
              certReview?.enddate
                ? moment.utc(certReview.enddate).format("YYYY.MM.DD")
                : "------"
            }
            canEditNist={canEditNist}
            certReview={certReview}
          />
        </Col>
      </Row>
    </>
  );
};

export default NISTHeader;
