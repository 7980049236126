import { baseApi } from "api/BaseApi";

import { TdVotingPayload, Vote, VotingUpdatePayload } from "../voting.types";

const TD_VOTING_BASE_QUERY = "technical-decision/voting";

const tdVotingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    tdVotesById: build.query<Vote[], number>({
      query: (tdId) => ({
        url: `${TD_VOTING_BASE_QUERY}/td_votes_by_td_id/`,
        params: { td_id: tdId },
      }),
      providesTags: ["TD_VOTES"],
    }),

    deleteTdVote: build.mutation<void, number>({
      query: (userId) => ({
        url: `${TD_VOTING_BASE_QUERY}/${userId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TD_VOTES"],
    }),

    updateTdVote: build.mutation<void, VotingUpdatePayload>({
      query: ({ userId, body }) => ({
        url: `${TD_VOTING_BASE_QUERY}/${userId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TD_VOTES"],
    }),

    addTdVote: build.mutation<void, TdVotingPayload>({
      query: (body) => ({
        url: `${TD_VOTING_BASE_QUERY}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TD_VOTES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useTdVotesByIdQuery,
  useDeleteTdVoteMutation,
  useUpdateTdVoteMutation,
  useAddTdVoteMutation,
} = tdVotingApi;
