import parse from "html-react-parser";
import moment from "moment";
import { useState } from "react";
import { Row } from "react-bootstrap";

import { useGetAllNistCommentsQuery } from "api/memberPortal/certReview/certReview.api";

import NISTCommentModal from "../NISTCommentModal";

import DeleteCommentModal from "./DeleteCommentModal";

const DraftResponseComments = ({
  certReview,
  showCommentModal,
  setShowCommentModal,
}) => {
  const [selectedUpdate, setSelectedUpdate] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteComment, setToDeleteComment] = useState({});
  const { data: comments = [] } = useGetAllNistCommentsQuery(certReview?.crid, {
    skip: !certReview?.crid,
  });

  const openDeleteModal = (update) => {
    setToDeleteComment(update);
    setShowDeleteModal(true);
  };

  return (
    <Row className="mt-3">
      <NISTCommentModal
        show={showCommentModal}
        onHide={() => {
          setShowCommentModal(false);
          setSelectedUpdate({});
        }}
        certReview={certReview}
        selectedUpdate={selectedUpdate}
      />
      <DeleteCommentModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        toDeleteComment={toDeleteComment}
      />
      {comments?.map((comment) => (
        <div key={comment.upid}>
          <p className="text-end">
            <span
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedUpdate(comment);
                setShowCommentModal(true);
              }}
            >
              Update
            </span>
            <span
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => openDeleteModal(comment)}
            >
              Delete
            </span>
          </p>
          <div className="border border-secondary p-3 m-2">
            <p className="text-end">
              Note by {comment?.creator__first_name}{" "}
              {comment?.creator__last_name} <br />
              {comment?.entrydate
                ? moment.utc(comment?.entrydate).format("YYYY.MM.DD")
                : "---"}
            </p>
            {comment?.note1 && parse(comment?.note1)}
          </div>
        </div>
      ))}
    </Row>
  );
};

export default DraftResponseComments;
