import FileDownload from "@mui/icons-material/FileDownload";
import axios from "axios";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Stack,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { calculateActiveDaysInProgress } from "utils/timeUtils";

export default function NiapPostingHeader({
  product,
  refetch,
  maintenances,
  projpps,
  certificate,
  downloadFile,
}) {
  const { authToken, csrfToken } = useAuth();
  const latestApprovedMaintenance = maintenances
    ?.filter((maintenance) => maintenance.status === "Approved")
    ?.sort((a, b) =>
      new Date(a.completion_date).getTime() <
      new Date(b.completion_date).getTime()
        ? 1
        : -1,
    )[0];

  const getAMColumnDetails = () => {
    if (product?.current_assurance_maintenance !== null) {
      return (
        <Link
          to={`assurancemaintenance/${product?.current_assurance_maintenance?.maintenance_id}`}
          relative="route"
          className="text-decoration-underlined"
        >
          {product?.current_assurance_maintenance?.title}
        </Link>
      );
    } else if (latestApprovedMaintenance) {
      return (
        <Stack>
          <Link
            to={`assurancemaintenance/${latestApprovedMaintenance?.maintenance_id}`}
            relative="route"
            className="text-decoration-underlined"
          >
            {latestApprovedMaintenance?.title}
          </Link>
          <p>
            {latestApprovedMaintenance?.completion_date
              ? moment
                  .utc(latestApprovedMaintenance?.completion_date)
                  .format("MM/DD/YYYY")
              : "TBD"}
          </p>
        </Stack>
      );
    }
  };

  const daysInProgress = calculateActiveDaysInProgress(product);
  const daysInProgressDisplay = daysInProgress > 0 ? daysInProgress : "Pending";

  const submitAMDateChange = (date) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
        {
          assurance_maintenance_date: date,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
      })
      .catch((error) => console.log(error));
  };

  const submitDataChange = (date) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
        {
          certification_date: date,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Row>
        <Col className="m-auto" md={12} lg={6}>
          <h3 className="fw-bold text-primary">EVALUATION PROJECT DETAILS: </h3>
          {/* {product?.post_to_iccc && <p className="text-success">This product certificate will be presented at the ICCC</p>} */}
        </Col>
        {
          <Col md={12} lg={6}>
            <Form.Group className="mb-3" controlId="certification_date">
              <Form.Label className="small-font fw-bold">
                Select Date for Certificate:
              </Form.Label>
              <Form.Control
                style={{ width: "fit-content" }}
                type="date"
                name="certification_date"
                defaultValue={product?.certification_date
                  ?.toString()
                  .substring(0, 10)}
                onChange={(e) => submitDataChange(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="assurance_maintenance_date">
              <Form.Label className="small-font fw-bold">
                Select Date for Assurance Maintenance:
              </Form.Label>
              <Form.Control
                style={{ width: "fit-content" }}
                type="date"
                name="assurance_maintenance_date"
                defaultValue={product?.assurance_maintenance_date
                  ?.toString()
                  .substring(0, 10)}
                onChange={(e) =>
                  submitAMDateChange(
                    product?.assurance_maintenance_date
                      ? e.target.value
                      : moment.utc().add(2, "years").format("YYYY-MM-DD"),
                  )
                }
              />
            </Form.Group>
          </Col>
        }
      </Row>
      <Row>
        <Col sm={6} className="mt-3">
          <h3 className="fw-bold">{product?.vendor_id?.name}</h3>
          <h4 className="fw-bold text-info">VID {product?.v_id}</h4>
        </Col>
        <Col sm={6} className="mt-3 d-flex align-items-center ">
          <div className="vr me-3"></div>
          <h3 className="fw-bold">{product?.product_name}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Protection Profiles</h5>
            {projpps?.map((projpp) => (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={
                  <Popover>
                    <Popover.Body>{projpp?.pp?.pp_name}</Popover.Body>
                  </Popover>
                }
                key={projpp?.projpp_id}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp?.pp?.pp_id}`}
                  className="text-secondary"
                >
                  <p className="py-0 my-0">{projpp?.pp?.pp_short_name}</p>
                </Link>
              </OverlayTrigger>
            ))}
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Kickoff Date</h5>

            <h5 className="text-secondary">
              {product?.kicked_off_date
                ? moment(product?.kicked_off_date).format("MM/DD/YYYY")
                : "Pending"}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Days In Progress</h5>
            <h5 className="text-secondary">
              <p>{daysInProgressDisplay}</p>
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Status</h5>

            <h5 className="text-secondary">
              {product?.status === "Certified" ? "Completed" : product?.status}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Posted Date</h5>

            <h5 className="text-secondary">
              {product?.posted_date
                ? moment.utc(product?.posted_date).format("MM/DD/YYYY")
                : "TBD"}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Certificate Issued</h5>

            <h5 className="text-secondary">
              {product?.certification_date
                ? moment.utc(product?.certification_date).format("MM/DD/YYYY")
                : "TBD"}
            </h5>
            {certificate?.certificate?.file_id && (
              <Button variant="link" onClick={() => downloadFile(certificate)}>
                <FileDownload />
                Download
              </Button>
            )}
            {/* {certificate?.file_id && <Button variant="link" onClick={updateCertificate}><PriorityHigh />Update</Button>} */}
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Assurance Maintenance Details</h5>
            {getAMColumnDetails()}
          </Stack>
        </Col>
      </Row>
    </>
  );
}
