import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import { useAuth } from "hooks/useAuth";

import ReactSelect from "../../../ReactSelect";

import csfcClaimOptions from "./CSFCClaimOptions";

export default function VendorLabInfoFormSection({
  product,
  editedProduct,
  handleChange,
  handleChangeForm,
  vendorValid,
  selectedComponents,
}) {
  const { authToken } = useAuth();

  const [vendors, setVendors] = useState([]);
  const permissions = useSelector((state) => state.role.value);
  const [showCSFCOptions, setShowCSFCOptions] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_vendors`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setVendors(response.data))
      .catch((error) => console.log(error));
  }, [authToken]);

  const vendorOptions = vendors?.map((vendor) => ({
    value: vendor.org_id,
    label: vendor.name,
  }));

  useEffect(() => {
    if (
      editedProduct?.csfc_eligible === "Yes" ||
      editedProduct?.csfc_eligible === "Maybe"
    ) {
      setShowCSFCOptions(true);
    } else {
      setShowCSFCOptions(false);
    }
  }, [editedProduct?.csfc_eligible]);

  let vendor_id = product?.vendor_id?.org_id
    ? product?.vendor_id?.org_id
    : product?.vendor_id;

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <p className="fw-bold form-title">VENDOR INFORMATION</p>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="vendor">
            <Form.Label>Vendor:*</Form.Label>
            <ReactSelect
              id="checkin-form-vendor-select"
              options={vendorOptions}
              newObj={editedProduct}
              handleChange={handleChange}
              name="vendor_id"
              isValid={vendorValid}
              defaultValue={vendorOptions?.find(
                (vendor) => vendor_id === vendor.value,
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="vendor_poc">
            <Form.Label>Vendor POC:</Form.Label>
            <Form.Control
              name="vendor_poc"
              defaultValue={product?.vendor_poc}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        {permissions?.role_permissions?.find(
          (permission) => permission.area === "Organization",
        )?.create && (
          <Row className="mt-4 mb-3">
            <p>Can't find the Vendor you are looking for?</p>
            <Col>
              <Button
                variant="primary"
                onClick={(e) => handleChangeForm(e, "vendor")}
              >
                Click to add new Vendor
              </Button>
            </Col>
          </Row>
        )}
      </Row>
      <Row>
        <Row className="mb-3">
          <Col>
            <p>Is the vendor interested in CSfC?</p>
          </Col>
          <Col>
            <Form.Group className="" controlId="csfc_eligible">
              {/* <Form.Label>Is the vendor interested in CSFC?</Form.Label> */}
              <Form.Select
                name="csfc_eligible"
                data-testid="select-option"
                onChange={handleChange}
                defaultValue={product.csfc_eligible}
                required
              >
                <option></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {(product?.csfc_eligible === "Yes" ||
          product?.csfc_eligible === "Maybe" ||
          showCSFCOptions ||
          editedProduct?.csfc_eligible === "Yes" ||
          editedProduct?.csfc_eligible === "Maybe") && (
          <Row className="mt-2">
            <Row className="mb-2">
              <p>
                If yes or maybe, which component is the vendor claiming for
                CSfC?*{" "}
              </p>
            </Row>
            <Form.Group>
              <Row
                className="display-flex"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {csfcClaimOptions.map((option, i) => (
                  <Col
                    key={`${option.name}${i}`}
                    className="mb-3"
                    style={{ flexBasis: "33.33%" }}
                  >
                    <Form.Check
                      inline
                      type="checkbox"
                      key={`${i}${option.name}`}
                      id={option.name}
                      name="csfc_components"
                      value={option.name}
                      label={<span className="align-text">{option.name}</span>}
                      checked={selectedComponents?.find(
                        (cs) =>
                          cs?.component === option.name && cs?.active === true,
                      )}
                      onChange={handleChange}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          </Row>
        )}
      </Row>
    </Container>
  );
}
